/**
 * File to set containers and props on load
 */

import ProjectSettings from "../containers/ProjectSettings";
import Overview from "../containers/Overview";
import Profile from "../containers/Profile";
import Projects from "../containers/Projects";
import ProjectCampaignOverview from "../containers/ProjectCampaignOverview";
import AdminPage from "../containers/admin/AdminPage";
import HelpCenter from "../containers/help_center/HelpCenter";
import WellDelivered from "../containers/help_center/WellDelivered";
import WellSecured from "../containers/help_center/WellSecured";
import WellOverview from "../containers/WellOverview";
import WellSettings from "../containers/WellSettings";
import TaskOverview from "../containers/TaskOverview";
import PhaseOverview from "../containers/PhaseOverview";
import WellProgrammeTemplate from "../containers/WellProgrammeTemplate";
import PWellOverview from "../containers/PWellOverview";
import WellDetails from "../containers/WellDetails";

export const containerList = [];

export const adminContainerList = [];

export const setContainer = (
	component: any,
	title: any,
	key: any,
	params: any,
	role: any
) => {
	switch (component) {
		case "dashboard":
			return <Overview params={params} />;
		case "projects":
			return <Projects params={params} />;
		case "projectCampaignOverview":
			return <ProjectCampaignOverview params={params} />;
		case "WellOverview":
			return <WellOverview params={params} />;
		case "WellSettings":
			return <WellSettings params={params} />;
		case "projectSettings":
			return <ProjectSettings params={params} />;
		case "profile":
			return <Profile params={params} />;
		case "admin":
			return <AdminPage params={params} />;
		case "wellProgrammeTemplate":
			return <WellProgrammeTemplate params={params} />;
		case "helpCenter":
			return <HelpCenter params={params} />;
		case "well-delivered":
			return <WellDelivered params={params} />;
		case "well-secured":
			return <WellSecured params={params} />;
		case "PhaseOverview":
			return <PhaseOverview params={params} />;
		case "TaskOverview":
			return <TaskOverview params={params} />;
		case "wells":
			return <PWellOverview params={params} />;
		case "wellDetails":
			return <WellDetails params={params} />;
	}
};

export const setAdminContainer = (
	component: any,
	params: any | null = null
) => {
	switch (component) {
	}
};
