import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";

export const sendErrorNotification = (errorMessage: any) => {
	axios
		.post(`${url}/errorNotification`, {
			errorMessage,
		})
		.then((_data: any) => {});
};
