const mimeTypeMap: any = {
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
		"Excel spreadsheet",
	"application/vnd.ms-excel": "Excel spreadsheet",
	"application/msword": "Word document",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document":
		"Word document",
	"application/pdf": "PDF document",
	"image/jpeg": "JPEG image",
	"image/png": "PNG image",
	"image/gif": "GIF image",
	"image/svg+xml": "SVG image",
	"application/zip": "ZIP archive",
	"application/x-rar-compressed": "RAR archive",
	"application/x-7z-compressed": "7-Zip archive",
	"application/x-tar": "TAR archive",
	"application/json": "JSON file",
	"text/plain": "Text file",
	"text/csv": "CSV file",
	"text/html": "HTML document",
	"application/javascript": "JavaScript file",
	"application/xml": "XML document",
	"audio/mpeg": "MP3 audio",
	"audio/wav": "WAV audio",
	"video/mp4": "MP4 video",
	"video/x-msvideo": "AVI video",
	"video/webm": "WebM video",
	"application/ogg": "OGG audio/video",
	"application/vnd.openxmlformats-officedocument.presentationml.presentation":
		"PowerPoint presentation",
	"application/vnd.ms-powerpoint": "PowerPoint presentation",
	"application/x-shockwave-flash": "Flash file",
	"application/octet-stream": "Binary file",
	"application/x-bittorrent": "Torrent file",
	"application/vnd.android.package-archive": "Android APK",
	"application/x-msdownload": "Windows executable file",
	"application/x-debian-package": "Debian package file",
	"application/x-iso9660-image": "ISO disk image",
	"application/x-rpm": "RedHat package file",
	"application/x-sh": "Shell script",
	"application/x-python": "Python script",
	"application/x-httpd-php": "PHP file",
	"text/markdown": "Markdown document",
};

export const getReadableFileType = (mimeType: string) => {
	return mimeTypeMap[mimeType] || mimeType;
};

export const formatFileSize = (bytes: number) => {
	if (bytes === 0) return "0 KB";

	const k = 1024;
	const sizes = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	// Convert bytes to kilobytes first
	const sizeInKB = bytes / k;

	// If the size in KB is less than 1, always return KB
	if (sizeInKB < 1) {
		return parseFloat(sizeInKB.toFixed(3)) + " KB";
	}

	// Determine the appropriate unit
	const i = Math.floor(Math.log(sizeInKB) / Math.log(k));

	// Ensure the output is in KB or higher
	return parseFloat((sizeInKB / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};
