/**
 * Main source of the client react system
 * 	- Stores & Create routes & pages
 * 	- Auto navigate logic
 *  - Check logins & tokens
 *  -
 */

import React, { useEffect, useMemo, useState } from "react";
import logo from "./logo.svg";
import "./App.scss";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	Outlet,
} from "react-router-dom";
import { Alert, ConfigProvider, theme } from "antd";
import Location from "./utils/location";
import Home from "./containers/Home";
import LoginPage from "./containers/LoginPage";
import Overview from "./containers/Overview";
import LoginSuccess from "./services/LoginSuccess";
import jwtDecode from "jwt-decode";
import Profile from "./containers/Profile";
import { initialExtraMenu, initialMainMenu } from "./services/MainMenu";
import { setContainer } from "./utils/containers";
import { addUser, getUser } from "./services/api-server/user";
import Emitter from "./utils/emitter";
import { socket } from "./utils/socket";
import { logout, checkPrivilege } from "./utils/utils";
import { refreshToken } from "./services/api-server/usertoken";
import NoAccess from "./containers/NoAccess";
import { superAdminRole } from "./utils/_exports";
import { getAppRoles, getAppUsers } from "./services/api-server/graphql";
import { GetAntIcon } from "./utils/ant_icons";
import {
	getSpecificTenants,
	getTenantList,
} from "./services/api-server/tenant_management";
import { products as helpCenterProducts } from "./containers/help_center/HelpCenter";
import useTheme from "./hooks/useTheme";
import { ThemeContext } from "./contexts/context";

export const Mode = `${process.env.REACT_APP_MODE}`;
export let Tenant = `elemental`;

const { defaultAlgorithm, darkAlgorithm } = theme;

let tokenCheck: any = null;
const restricted = ["/", "loginsuccess", "logout"];

const App = () => {
	let isLogin = false;
	let shouldRedirect = false;
	let currentDate: any = Date.now() / 1000;
	let timeout: any = null;

	const restricted = [
		"",
		"/",
		"/loginsuccess",
		"/loginsuccess/",
		"/logout",
		"/logout/",
	];

	const [user, setUser] = useState<any>(null);
	const [userRole, setUserRole] = useState<any>([]);
	const [menu, setMenu] = useState<[]>([]);
	const [menuRoutes, setMenuRoutes] = useState<[]>([]);
	const [adminMenuRoutes, setAdminMenuRoutes] = useState<[]>([]);
	const [currentLocation, setCurrentLocation] = useState<any>(
		window.location.pathname
	);
	const [accessTokenDecoded, setAccessTokenDecoded] = useState<any>(null);
	const [idTokenDecoded, setIDTokenDecoded] = useState<any>(null);

	const [alert, setAlert] = useState<any>(null);

	const [tenantDataList, setTenantDataList] = useState<any>(null);
	const [initialLoad, setInitialLoad] = useState<any>(true);

	// Theme related states
	const [defaultTheme, setDefaultTheme] = useState<boolean>(true);
	const [theme, setTheme] = useState<any>();
	const [themeVariables, setThemeVariables] = useState<any>(null);

	const { setLightDarkMode, setCustomTheme } = useTheme();

	// This is to define the current url location of the user
	// This is mostly use to define
	const onLocationChange = (location: Location) => {
		if (initialLoad) {
			setInitialLoad(false);
		} else {
			localStorage.removeItem("selectedFilters");
		}
		setCurrentLocation(location.pathname);
	};

	// Quick simple check for sign-ins
	localStorage.removeItem("isLogin");
	if (!restricted.includes(currentLocation)) {
		if (
			(!localStorage.getItem("accessToken") &&
				!localStorage.getItem("idToken")) ||
			localStorage.getItem("accessToken") === "undefined" ||
			localStorage.getItem("idToken") === "undefined"
		) {
			localStorage.removeItem("accessToken");
			localStorage.removeItem("idToken");
			shouldRedirect = true;
		} else {
			console.log(true);
			isLogin = true;
		}
	}

	// Get user information from mongoDB, and populate user info into the App
	const getUserInfo = (email: any, name: any) => {
		getUser(email)
			.then((data: any) => {
				if (data) {
					setUser(data[0]);
					setTheme(data[0]?.theme || "dark");
				} else {
					addUser({
						name: name,
						email: email,
						theme: "dark",
					})
						.then((data: any) => {
							setUser(data);
							setTheme(data?.theme || "dark");
						})
						.catch((error: any) => {
							console.log(error);
						});
				}
			})
			.catch((error: any) => {
				console.log(error);
				logout(true);
			});
	};

	// Emitter controller - Control events within the app itself
	const emitterController = () => {
		Emitter.on("userSaved", (data: any) => {
			getUserInfo(data.email, data.name);
		});

		Emitter.on("alert", (payload: any) => {
			if (payload) {
				if (payload.timeout) {
					if (timeout) {
						clearTimeout(timeout);
						timeout = null;
					}
					timeout = setTimeout(() => {
						setAlert(null);
					}, payload.timeout);
				}
				setAlert({
					type: payload.type,
					message: payload.message,
					description: payload.description,
					top: payload.top,
					closeable: payload.closable,
				});
			} else {
				setAlert(null);
			}
		});
	};

	// Socket Controller - control events with the back-end server and will conduct all socket io messages
	const socketController = () => {
		const socketServer: string = process.env.REACT_APP_SOCKET_SERVER as string;
		// const socketIDToken: any = localStorage.getItem(`${Tenant}:idToken`);

		socket.on("connect", () => {
			setInterval(() => {
				const start = Date.now();

				socket.emit("ping", () => {
					const duration = Date.now() - start;
					// console.log("Latency:", duration + "ms");
				});
			}, 1000);
		});
	};

	// Token Controller - to check initial token expiry and set tokens for user info usage
	const tokenController = (
		email: any,
		idTokenDecoded: any,
		accessTokenDecoded: any
	) => {
		let currentDate = Date.now() / 1000;
		if (idTokenDecoded && accessTokenDecoded) {
			if (currentDate > accessTokenDecoded?.exp - 5 * 60) {
				if (currentDate > accessTokenDecoded?.exp + 30 * 60) {
					logout(true);
				} else {
					//RefreshToken
					refreshToken(email)
						.then((refreshed_token: any) => {
							setAccessTokenDecoded(jwtDecode(refreshed_token));
							Emitter.emit("refreshed", null);
						})
						.catch(() => {
							logout(true);
						});
				}
			}
		} else {
			if (!idTokenDecoded) {
				alert("NO ID TOKEN FOUND");
				logout(true);
			} else if (!accessTokenDecoded) {
				alert("NO ACCESS TOKEN FOUND");
				logout(true);
			} else {
				alert("You have been log out for unknown reasons");
				logout(true);
			}
		}
	};

	// Check Login && Redirect
	useEffect(() => {
		if (!restricted.includes(currentLocation) && !user)
			try {
				const idToken_decoded: any = jwtDecode(
					localStorage.getItem("accessToken") || ""
				);
				const accessToken_decoded: any = jwtDecode(
					localStorage.getItem("idToken") || ""
				);

				emitterController();
				socketController();
				tokenController(
					idToken_decoded.preferred_username,
					idToken_decoded,
					accessToken_decoded
				);
				setAccessTokenDecoded(accessToken_decoded);
				setIDTokenDecoded(idToken_decoded);
				getAppRoles().then((values: any) => {
					console.log(values);
				});
				getAppUsers().then((values: any) => {
					console.log(values);
				});
				getUserInfo(idToken_decoded.preferred_username, idToken_decoded.name);
				// setUserRole(idToken_decoded.roles);
			} catch (err: any) {
				logout(true);
			}
	}, [currentLocation]);

	// Interval check for token expiry of users
	useEffect(() => {
		if (tokenCheck) clearInterval(tokenCheck);

		if (idTokenDecoded && accessTokenDecoded)
			tokenCheck = setInterval(() => {
				tokenController(
					idTokenDecoded?.preferred_username,
					idTokenDecoded,
					accessTokenDecoded
				);
			}, 60000);
	}, [accessTokenDecoded, idTokenDecoded]);

	// Load Menus and populate routes
	useEffect(() => {
		if (user && tenantDataList) {
			const mainMenu: any = initialMainMenu;
			const extraMenu: any = initialExtraMenu;
			let allMenu: any = [...mainMenu, ...extraMenu];

			const menuRoutes: any = [];
			const adminMenuRoutes: any = [];
			let params: any = { user, userRole, tenantDataList };
			const traverse = (menuItems: any, parentPath: any = "") => {
				menuItems.forEach((item: any) => {
					let route = null;
					if (item.to) {
						if (item.requires_admin) {
							if (params?.userRole?.includes(superAdminRole)) {
								route = (
									<>
										<Route
											key={item.key}
											// path={"admin/" + item.to.split("/").pop()}
											path={item.to.split("/").pop()}
											element={setContainer(
												item.container,
												item.propTitle,
												item.key,
												{ ...params, ...item, user: user },
												userRole
											)}
										/>
										{item.container === "admin" && (
											<Route
												key={item.key}
												// path={"admin/" + item.to.split("/").pop()}
												path={item.to.split("/").pop() + "/programme-settings"}
												element={setContainer(
													"wellProgrammeTemplate",
													"Well Programme Template",
													"wellProgrammeTemplate",
													{
														key: "well-programme-template",
														label: "Well Programme Template",
														propTitle: "Well Programme Template",
														container: "Well Programme Template",
														icon: GetAntIcon("project"),
														requires_admin: true,
														...params,
														userRole,
														user: user,
													},
													userRole
												)}
											/>
										)}
									</>
								);
							} else {
								route = (
									<Route
										key={item.key}
										// path={"admin/" + item.to.split("/").pop()}
										path={item.to.split("/").pop()}
										element={
											<NoAccess
												break={true}
												text={
													"Oops, looks like you don't have the authorisation to view this page."
												}
											/>
										}
									/>
								);
							}
						} else {
							if (item.container === "projects") {
								route = (
									<>
										<Route
											key={item.key}
											path={item.to.split("/").pop()}
											element={setContainer(
												item.container,
												item.propTitle,
												item.key,
												{ ...params, ...item, userRole, user: user },
												userRole
											)}
										></Route>
										<Route
											key={"projectsOverview"}
											path={item.to.split("/").pop() + "/:projectname"}
											element={setContainer(
												"projectCampaignOverview",
												"Project Overview",
												"projectCampaignOverview",
												{
													key: "project-menu",
													label: "Project Overview",
													propTitle: "Project Overview",
													container: "projectCampaignOverview",
													icon: GetAntIcon("project"),
													to: `/overview`,
													requires_admin: true,
													...params,
													userRole,
													user: user,
												},
												userRole
											)}
										></Route>
										<Route
											key={"addNewProject"}
											path={item.to.split("/").pop() + "/add-new-project"}
											element={
												checkPrivilege(userRole, ["project-manager"]) ? (
													setContainer(
														"projectSettings",
														"Projects",
														"projectSettings",
														{
															key: "project-menu",
															label: "projectSettings",
															propTitle: "projectSettings",
															container: "projectSettings",
															icon: GetAntIcon("project"),
															to: "/projects",
															requires_admin: true,
															...params,
															userRole,
															user: user,
														},
														userRole
													)
												) : (
													<NoAccess
														break={true}
														text={
															"Oops, looks like you don't have the authorisation to view this page."
														}
													/>
												)
											}
										></Route>
										<Route
											key={"projects"}
											path={
												item.to.split("/").pop() +
												"/:projectname/project-settings"
											}
											element={
												checkPrivilege(userRole, ["project-manager"]) ? (
													setContainer(
														"projectSettings",
														"Projects",
														"projectSettings",
														{
															key: "project-menu",
															label: "projectSettings",
															propTitle: "projectSettings",
															container: "projectSettings",
															icon: GetAntIcon("project"),
															to: "/projects",
															requires_admin: true,
															...params,
															userRole,
															user: user,
														},
														userRole
													)
												) : (
													<NoAccess
														break={true}
														text={
															"Oops, looks like you don't have the authorisation to view this page."
														}
													/>
												)
											}
										></Route>
										<Route
											key={"projects"}
											path={
												item.to.split("/").pop() + "/:projectname/:wellname"
											}
											element={setContainer(
												"WellOverview",
												"wellOverview",
												"WellOverview",
												{
													key: "project-well-overview",
													label: "wellOverview",
													propTitle: "wellOverview",
													container: "wellOverview",
													icon: GetAntIcon("project"),
													to: "/projects",
													requires_admin: true,
													...params,
													userRole,
													user: user,
												},
												userRole
											)}
										></Route>
										<Route
											key={"projects"}
											path={
												item.to.split("/").pop() +
												"/:projectname/:wellname/well-settings"
											}
											element={
												checkPrivilege(userRole, ["project-manager"]) ? (
													setContainer(
														"WellSettings",
														"wellSettings",
														"WellSettings",
														{
															key: "project-well-settings",
															label: "WellSettings",
															propTitle: "WellSettings",
															container: "WellSettings",
															icon: GetAntIcon("project"),
															requires_admin: true,
															...params,
															userRole,
															user: user,
														},
														userRole
													)
												) : (
													<NoAccess
														break={true}
														text={
															"Oops, looks like you don't have the authorisation to view this page."
														}
													/>
												)
											}
										></Route>
										<Route
											key={"projects"}
											path={
												item.to.split("/").pop() +
												"/:projectname/:wellname/:phasename"
											}
											element={setContainer(
												"PhaseOverview",
												"phaseOverview",
												"PhaseOverview",
												{
													key: "task-overview",
													label: "phaseOverview",
													propTitle: "phaseOverview",
													container: "phaseOverview",
													icon: GetAntIcon("project"),
													to: "/projects",
													requires_admin: true,
													...params,
													userRole,
													user: user,
												},
												userRole
											)}
										></Route>
										<Route
											key={"projects"}
											path={
												item.to.split("/").pop() +
												"/:projectname/:wellname/:phasename/:taskname"
											}
											element={setContainer(
												"TaskOverview",
												"taskOverview",
												"TaskOverview",
												{
													key: "task-overview",
													label: "taskOverview",
													propTitle: "taskOverview",
													container: "taskOverview",
													icon: GetAntIcon("project"),
													to: "/projects",
													requires_admin: true,
													...params,
													userRole,
													user: user,
												},
												userRole
											)}
										></Route>
									</>
								);
							} else if (item.container === "wells") {
								route = (
									<>
										<Route
											key={item.key}
											path={item.to.split("/").pop()}
											element={setContainer(
												item.container,
												item.propTitle,
												item.key,
												{ ...params, ...item, userRole, user: user },
												userRole
											)}
										></Route>
										<Route
											key={"wellDetails"}
											path={item.to.split("/").pop() + "/:wellcode"}
											element={setContainer(
												"wellDetails",
												"wellDetails",
												"wellDetails",
												{ ...params, ...item, userRole, user: user },
												userRole
											)}
										></Route>
									</>
								);
							} else if (item.container === "helpCenter") {
								route = (
									<>
										<Route
											key={item.key}
											path={item.to.split("/").pop()}
											element={setContainer(
												item.container,
												item.propTitle,
												item.key,
												{ ...params, ...item, userRole, user: user },
												userRole
											)}
										></Route>
										{helpCenterProducts.map((product: any) => {
											return (
												<Route
													key={`help-center-${product.key}`}
													path={item.to.split("/").pop() + `/${product.key}`}
													element={setContainer(
														product.key,
														product.label,
														product.key,
														{
															key: product.key,
															label: product.label,
															propTitle: product.propTitle,
															contents: product.contents,
															...params,
															userRole,
															user: user,
														},
														userRole
													)}
												></Route>
											);
										})}
									</>
								);
							} else {
								route = (
									<Route
										key={item.key}
										path={item.to.split("/").pop()}
										element={setContainer(
											item.container,
											item.propTitle,
											item.key,
											{ ...params, ...item, userRole, user: user },
											userRole
										)}
									/>
								);
							}
						}

						//Flat push
						if (item.requires_admin) {
							adminMenuRoutes.push({
								item: item,
								route: route,
							});
						} else {
							menuRoutes.push({
								item: item,
								route: route,
							});
						}
					}
					if (item.children && Array.isArray(item.children)) {
						traverse(item.children, parentPath + (item.to || ""));
					}
				});
			};
			traverse(allMenu);
			menuRoutes.push({
				item: null,
				route: <Route path="*" element={<NoAccess />} />,
			});
			adminMenuRoutes.push({
				item: null,
				route: <Route path="*" element={<NoAccess />} />,
			});
			setMenuRoutes(menuRoutes);
			setAdminMenuRoutes(adminMenuRoutes);
		}
	}, [userRole, user]);

	// set tenant role information
	useEffect(() => {
		const setTenant = () => {
			let tenantData: any = null;
			if (user) {
				let tenants = idTokenDecoded?.roles?.filter(
					(element: any) => element !== superAdminRole
				);
				if (
					tenants?.length > 0 ||
					idTokenDecoded?.roles?.includes(superAdminRole)
				) {
					Promise.all([
						getSpecificTenants(idTokenDecoded?.roles, tenants),
						getAppRoles(
							tenants,
							idTokenDecoded?.roles?.includes(superAdminRole)
								? superAdminRole
								: null
						),
					])
						.then((data: any) => {
							let tenantArray: any = data[1]
								.map((tenantRole: any) => {
									if (tenantRole.isEnabled) {
										return tenantRole;
									}
								})
								.filter(Boolean);
							let databaseTenantArray: any = data[0] || [];
							let combinedArray: any = [];
							tenantArray.forEach((appTenant: any) => {
								const foundObj = databaseTenantArray.find(
									(dbTenant: any) => dbTenant.uuid === appTenant.id
								);

								if (foundObj) {
									combinedArray.push({ ...appTenant, ...foundObj });
								} else {
									combinedArray.push(appTenant);
								}
							});

							combinedArray = combinedArray.sort((a: any, b: any) => {
								let aName = a.name || a.displayName;
								let bName = b.name || b.displayName;
								return aName.localeCompare(bName);
							});

							const tenant = localStorage.getItem("currentTenantKey");
							tenantData = combinedArray;
							if (tenantData) {
								if (tenant) {
									let foundTenant = tenantData?.find(
										(element: any) => element?.value === tenant
									);
									if (foundTenant) {
										console.log("Found Tenant", foundTenant);
										if (
											foundTenant?.project_managers?.some(
												(mail: any) =>
													mail.toLowerCase() === user.email.toLowerCase()
											)
										) {
											setUserRole([
												...idTokenDecoded?.roles,
												"project-manager",
											]);
										} else {
											setUserRole(idTokenDecoded?.roles || []);
										}
										setDefaultTheme(foundTenant?.default_theme);
										setThemeVariables(foundTenant?.theme_settings);
									} else {
										// Remove and refresh tenant and page
										localStorage.removeItem("currentTenantKey");
										window.location.href = window.location.href;
									}
								} else {
									if (tenantData.length > 0) {
										if (
											tenantData[0]?.project_managers?.some(
												(mail: any) =>
													mail.toLowerCase() === user.email.toLowerCase()
											)
										) {
											setUserRole([
												...idTokenDecoded?.roles,
												"project-manager",
											]);
										} else {
											setUserRole(idTokenDecoded?.roles || []);
										}
									} else {
										setUserRole(idTokenDecoded?.roles || []);
									}
								}
							} else {
								setUserRole(idTokenDecoded?.roles || []);
							}
						})
						.catch((error: any) => {
							console.log(error);
						})
						.finally(() => {
							setTenantDataList(tenantData || []);
						});
				} else {
					setUserRole(idTokenDecoded?.roles || []);
					setTenantDataList([]);
				}
			}
		};

		setTenant();
		Emitter.on("tenantUpdated", () => {
			setTenant();
		});
	}, [user, idTokenDecoded]);

	useEffect(() => {
		const handlePopstate = () => {
			sessionStorage.removeItem("main-tabkey");
			sessionStorage.removeItem("admin-tabkey");
			sessionStorage.removeItem("tabkey");
		};

		// Add event listener for popstate when component mounts
		window.addEventListener("popstate", handlePopstate);

		// Remove event listener when component unmounts
		return () => {
			window.removeEventListener("popstate", handlePopstate);
		};
	}, []);

	useEffect(() => {
		setLightDarkMode(theme, defaultTheme);

		if (defaultTheme === false && themeVariables) {
			setCustomTheme(themeVariables, theme);
		}
	}, [theme, defaultTheme, themeVariables]);

	return (
		<ConfigProvider theme={{ hashed: false, algorithm: darkAlgorithm }}>
			<ThemeContext.Provider
				value={{
					defaultTheme: defaultTheme,
					setDefaultTheme: setDefaultTheme,
					mode: theme,
					themeVariables: themeVariables,
					setThemeVariables: setThemeVariables,
				}}
			>
				<div className="main-page">
					<Router>
						<Location onChange={onLocationChange}></Location>
						{shouldRedirect ? <Navigate to="" /> : <></>}
						<Routes>
							<Route path="" element={<LoginPage />} />
							<Route path="loginsuccess" element={<LoginSuccess />} />
							{isLogin ? (
								<>
									{" "}
									<Route
										path=""
										element={
											<Home
												tenants={tenantDataList}
												userInfo={{ user, userRole }}
												currentLocation={currentLocation}
												menu={[...menuRoutes, ...adminMenuRoutes]}
											/>
										}
									>
										{menuRoutes.map((menuItem: any) => {
											return menuItem.route;
										})}
										{adminMenuRoutes.map((menuItem: any) => {
											return menuItem.route;
										})}
									</Route>
								</>
							) : (
								<></>
							)}
						</Routes>
					</Router>
					{alert && (
						<Alert
							className={
								alert?.top ? "alert-message-box-top" : "alert-message-box"
							}
							type={alert?.type}
							message={alert?.message}
							description={alert?.description}
							showIcon
							closable={alert?.closable || false}
							afterClose={() => setAlert(null)}
						/>
					)}
				</div>
			</ThemeContext.Provider>
		</ConfigProvider>
	);
};

export default App;
