export const countryCodes: any = [
	{
		code: "+7 840",
		label: "Abkhazia",
		value: "Abkhazia",
	},
	{
		code: "+93",
		label: "Afghanistan",
		value: "Afghanistan",
	},
	{
		code: "+355",
		label: "Albania",
		value: "Albania",
	},
	{
		code: "+213",
		label: "Algeria",
		value: "Algeria",
	},
	{
		code: "+1 684",
		label: "American Samoa",
		value: "American Samoa",
	},
	{
		code: "+376",
		label: "Andorra",
		value: "Andorra",
	},
	{
		code: "+244",
		label: "Angola",
		value: "Angola",
	},
	{
		code: "+1 264",
		label: "Anguilla",
		value: "Anguilla",
	},
	{
		code: "+1 268",
		label: "Antigua and Barbuda",
		value: "Antigua and Barbuda",
	},
	{
		code: "+54",
		label: "Argentina",
		value: "Argentina",
	},
	{
		code: "+374",
		label: "Armenia",
		value: "Armenia",
	},
	{
		code: "+297",
		label: "Aruba",
		value: "Aruba",
	},
	{
		code: "+247",
		label: "Ascension",
		value: "Ascension",
	},
	{
		code: "+61",
		label: "Australia",
		value: "Australia",
	},
	{
		code: "+672",
		label: "Australian External Territories",
		value: "Australian External Territories",
	},
	{
		code: "+43",
		label: "Austria",
		value: "Austria",
	},
	{
		code: "+994",
		label: "Azerbaijan",
		value: "Azerbaijan",
	},
	{
		code: "+1 242",
		label: "Bahamas",
		value: "Bahamas",
	},
	{
		code: "+973",
		label: "Bahrain",
		value: "Bahrain",
	},
	{
		code: "+880",
		label: "Bangladesh",
		value: "Bangladesh",
	},
	{
		code: "+1 246",
		label: "Barbados",
		value: "Barbados",
	},
	{
		code: "+1 268",
		label: "Barbuda",
		value: "Barbuda",
	},
	{
		code: "+375",
		label: "Belarus",
		value: "Belarus",
	},
	{
		code: "+32",
		label: "Belgium",
		value: "Belgium",
	},
	{
		code: "+501",
		label: "Belize",
		value: "Belize",
	},
	{
		code: "+229",
		label: "Benin",
		value: "Benin",
	},
	{
		code: "+1 441",
		label: "Bermuda",
		value: "Bermuda",
	},
	{
		code: "+975",
		label: "Bhutan",
		value: "Bhutan",
	},
	{
		code: "+591",
		label: "Bolivia",
		value: "Bolivia",
	},
	{
		code: "+387",
		label: "Bosnia and Herzegovina",
		value: "Bosnia and Herzegovina",
	},
	{
		code: "+267",
		label: "Botswana",
		value: "Botswana",
	},
	{
		code: "+55",
		label: "Brazil",
		value: "Brazil",
	},
	{
		code: "+246",
		label: "British Indian Ocean Territory",
		value: "British Indian Ocean Territory",
	},
	{
		code: "+1 284",
		label: "British Virgin Islands",
		value: "British Virgin Islands",
	},
	{
		code: "+673",
		label: "Brunei",
		value: "Brunei",
	},
	{
		code: "+359",
		label: "Bulgaria",
		value: "Bulgaria",
	},
	{
		code: "+226",
		label: "Burkina Faso",
		value: "Burkina Faso",
	},
	{
		code: "+257",
		label: "Burundi",
		value: "Burundi",
	},
	{
		code: "+855",
		label: "Cambodia",
		value: "Cambodia",
	},
	{
		code: "+237",
		label: "Cameroon",
		value: "Cameroon",
	},
	{
		code: "+1",
		label: "Canada",
		value: "Canada",
	},
	{
		code: "+238",
		label: "Cape Verde",
		value: "Cape Verde",
	},
	{
		code: "+345",
		label: "Cayman Islands",
		value: "Cayman Islands",
	},
	{
		code: "+236",
		label: "Central African Republic",
		value: "Central African Republic",
	},
	{
		code: "+235",
		label: "Chad",
		value: "Chad",
	},
	{
		code: "+56",
		label: "Chile",
		value: "Chile",
	},
	{
		code: "+86",
		label: "China",
		value: "China",
	},
	{
		code: "+61",
		label: "Christmas Island",
		value: "Christmas Island",
	},
	{
		code: "+61",
		label: "Cocos-Keeling Islands",
		value: "Cocos-Keeling Islands",
	},
	{
		code: "+57",
		label: "Colombia",
		value: "Colombia",
	},
	{
		code: "+269",
		label: "Comoros",
		value: "Comoros",
	},
	{
		code: "+242",
		label: "Congo",
		value: "Congo",
	},
	{
		code: "+243",
		label: "Congo, Dem. Rep. of (Zaire)",
		value: "Congo, Dem. Rep. of (Zaire)",
	},
	{
		code: "+682",
		label: "Cook Islands",
		value: "Cook Islands",
	},
	{
		code: "+506",
		label: "Costa Rica",
		value: "Costa Rica",
	},
	{
		code: "+385",
		label: "Croatia",
		value: "Croatia",
	},
	{
		code: "+53",
		label: "Cuba",
		value: "Cuba",
	},
	{
		code: "+599",
		label: "Curacao",
		value: "Curacao",
	},
	{
		code: "+537",
		label: "Cyprus",
		value: "Cyprus",
	},
	{
		code: "+420",
		label: "Czech Republic",
		value: "Czech Republic",
	},
	{
		code: "+45",
		label: "Denmark",
		value: "Denmark",
	},
	{
		code: "+246",
		label: "Diego Garcia",
		value: "Diego Garcia",
	},
	{
		code: "+253",
		label: "Djibouti",
		value: "Djibouti",
	},
	{
		code: "+1 767",
		label: "Dominica",
		value: "Dominica",
	},
	{
		code: "+1 809",
		label: "Dominican Republic",
		value: "Dominican Republic",
	},
	{
		code: "+670",
		label: "East Timor",
		value: "East Timor",
	},
	{
		code: "+56",
		label: "Easter Island",
		value: "Easter Island",
	},
	{
		code: "+593",
		label: "Ecuador",
		value: "Ecuador",
	},
	{
		code: "+20",
		label: "Egypt",
		value: "Egypt",
	},
	{
		code: "+503",
		label: "El Salvador",
		value: "El Salvador",
	},
	{
		code: "+240",
		label: "Equatorial Guinea",
		value: "Equatorial Guinea",
	},
	{
		code: "+291",
		label: "Eritrea",
		value: "Eritrea",
	},
	{
		code: "+372",
		label: "Estonia",
		value: "Estonia",
	},
	{
		code: "+251",
		label: "Ethiopia",
		value: "Ethiopia",
	},
	{
		code: "+500",
		label: "Falkland Islands",
		value: "Falkland Islands",
	},
	{
		code: "+298",
		label: "Faroe Islands",
		value: "Faroe Islands",
	},
	{
		code: "+679",
		label: "Fiji",
		value: "Fiji",
	},
	{
		code: "+358",
		label: "Finland",
		value: "Finland",
	},
	{
		code: "+33",
		label: "France",
		value: "France",
	},
	{
		code: "+596",
		label: "French Antilles",
		value: "French Antilles",
	},
	{
		code: "+594",
		label: "French Guiana",
		value: "French Guiana",
	},
	{
		code: "+689",
		label: "French Polynesia",
		value: "French Polynesia",
	},
	{
		code: "+241",
		label: "Gabon",
		value: "Gabon",
	},
	{
		code: "+220",
		label: "Gambia",
		value: "Gambia",
	},
	{
		code: "+995",
		label: "Georgia",
		value: "Georgia",
	},
	{
		code: "+49",
		label: "Germany",
		value: "Germany",
	},
	{
		code: "+233",
		label: "Ghana",
		value: "Ghana",
	},
	{
		code: "+350",
		label: "Gibraltar",
		value: "Gibraltar",
	},
	{
		code: "+30",
		label: "Greece",
		value: "Greece",
	},
	{
		code: "+299",
		label: "Greenland",
		value: "Greenland",
	},
	{
		code: "+1 473",
		label: "Grenada",
		value: "Grenada",
	},
	{
		code: "+590",
		label: "Guadeloupe",
		value: "Guadeloupe",
	},
	{
		code: "+1 671",
		label: "Guam",
		value: "Guam",
	},
	{
		code: "+502",
		label: "Guatemala",
		value: "Guatemala",
	},
	{
		code: "+224",
		label: "Guinea",
		value: "Guinea",
	},
	{
		code: "+245",
		label: "Guinea-Bissau",
		value: "Guinea-Bissau",
	},
	{
		code: "+595",
		label: "Guyana",
		value: "Guyana",
	},
	{
		code: "+509",
		label: "Haiti",
		value: "Haiti",
	},
	{
		code: "+504",
		label: "Honduras",
		value: "Honduras",
	},
	{
		code: "+852",
		label: "Hong Kong SAR China",
		value: "Hong Kong SAR China",
	},
	{
		code: "+36",
		label: "Hungary",
		value: "Hungary",
	},
	{
		code: "+354",
		label: "Iceland",
		value: "Iceland",
	},
	{
		code: "+91",
		label: "India",
		value: "India",
	},
	{
		code: "+62",
		label: "Indonesia",
		value: "Indonesia",
	},
	{
		code: "+98",
		label: "Iran",
		value: "Iran",
	},
	{
		code: "+964",
		label: "Iraq",
		value: "Iraq",
	},
	{
		code: "+353",
		label: "Ireland",
		value: "Ireland",
	},
	{
		code: "+972",
		label: "Israel",
		value: "Israel",
	},
	{
		code: "+39",
		label: "Italy",
		value: "Italy",
	},
	{
		code: "+225",
		label: "Ivory Coast",
		value: "Ivory Coast",
	},
	{
		code: "+1 876",
		label: "Jamaica",
		value: "Jamaica",
	},
	{
		code: "+81",
		label: "Japan",
		value: "Japan",
	},
	{
		code: "+962",
		label: "Jordan",
		value: "Jordan",
	},
	{
		code: "+7 7",
		label: "Kazakhstan",
		value: "Kazakhstan",
	},
	{
		code: "+254",
		label: "Kenya",
		value: "Kenya",
	},
	{
		code: "+686",
		label: "Kiribati",
		value: "Kiribati",
	},
	{
		code: "+965",
		label: "Kuwait",
		value: "Kuwait",
	},
	{
		code: "+996",
		label: "Kyrgyzstan",
		value: "Kyrgyzstan",
	},
	{
		code: "+856",
		label: "Laos",
		value: "Laos",
	},
	{
		code: "+371",
		label: "Latvia",
		value: "Latvia",
	},
	{
		code: "+961",
		label: "Lebanon",
		value: "Lebanon",
	},
	{
		code: "+266",
		label: "Lesotho",
		value: "Lesotho",
	},
	{
		code: "+231",
		label: "Liberia",
		value: "Liberia",
	},
	{
		code: "+218",
		label: "Libya",
		value: "Libya",
	},
	{
		code: "+423",
		label: "Liechtenstein",
		value: "Liechtenstein",
	},
	{
		code: "+370",
		label: "Lithuania",
		value: "Lithuania",
	},
	{
		code: "+352",
		label: "Luxembourg",
		value: "Luxembourg",
	},
	{
		code: "+853",
		label: "Macau SAR China",
		value: "Macau SAR China",
	},
	{
		code: "+389",
		label: "Macedonia",
		value: "Macedonia",
	},
	{
		code: "+261",
		label: "Madagascar",
		value: "Madagascar",
	},
	{
		code: "+265",
		label: "Malawi",
		value: "Malawi",
	},
	{
		code: "+60",
		label: "Malaysia",
		value: "Malaysia",
	},
	{
		code: "+960",
		label: "Maldives",
		value: "Maldives",
	},
	{
		code: "+223",
		label: "Mali",
		value: "Mali",
	},
	{
		code: "+356",
		label: "Malta",
		value: "Malta",
	},
	{
		code: "+692",
		label: "Marshall Islands",
		value: "Marshall Islands",
	},
	{
		code: "+596",
		label: "Martinique",
		value: "Martinique",
	},
	{
		code: "+222",
		label: "Mauritania",
		value: "Mauritania",
	},
	{
		code: "+230",
		label: "Mauritius",
		value: "Mauritius",
	},
	{
		code: "+262",
		label: "Mayotte",
		value: "Mayotte",
	},
	{
		code: "+52",
		label: "Mexico",
		value: "Mexico",
	},
	{
		code: "+691",
		label: "Micronesia",
		value: "Micronesia",
	},
	{
		code: "+1 808",
		label: "Midway Island",
		value: "Midway Island",
	},
	{
		code: "+373",
		label: "Moldova",
		value: "Moldova",
	},
	{
		code: "+377",
		label: "Monaco",
		value: "Monaco",
	},
	{
		code: "+976",
		label: "Mongolia",
		value: "Mongolia",
	},
	{
		code: "+382",
		label: "Montenegro",
		value: "Montenegro",
	},
	{
		code: "+1664",
		label: "Montserrat",
		value: "Montserrat",
	},
	{
		code: "+212",
		label: "Morocco",
		value: "Morocco",
	},
	{
		code: "+95",
		label: "Myanmar",
		value: "Myanmar",
	},
	{
		code: "+264",
		label: "Namibia",
		value: "Namibia",
	},
	{
		code: "+674",
		label: "Nauru",
		value: "Nauru",
	},
	{
		code: "+977",
		label: "Nepal",
		value: "Nepal",
	},
	{
		code: "+31",
		label: "Netherlands",
		value: "Netherlands",
	},
	{
		code: "+599",
		label: "Netherlands Antilles",
		value: "Netherlands Antilles",
	},
	{
		code: "+1 869",
		label: "Nevis",
		value: "Nevis",
	},
	{
		code: "+687",
		label: "New Caledonia",
		value: "New Caledonia",
	},
	{
		code: "+64",
		label: "New Zealand",
		value: "New Zealand",
	},
	{
		code: "+505",
		label: "Nicaragua",
		value: "Nicaragua",
	},
	{
		code: "+227",
		label: "Niger",
		value: "Niger",
	},
	{
		code: "+234",
		label: "Nigeria",
		value: "Nigeria",
	},
	{
		code: "+683",
		label: "Niue",
		value: "Niue",
	},
	{
		code: "+672",
		label: "Norfolk Island",
		value: "Norfolk Island",
	},
	{
		code: "+850",
		label: "North Korea",
		value: "North Korea",
	},
	{
		code: "+1 670",
		label: "Northern Mariana Islands",
		value: "Northern Mariana Islands",
	},
	{
		code: "+47",
		label: "Norway",
		value: "Norway",
	},
	{
		code: "+968",
		label: "Oman",
		value: "Oman",
	},
	{
		code: "+92",
		label: "Pakistan",
		value: "Pakistan",
	},
	{
		code: "+680",
		label: "Palau",
		value: "Palau",
	},
	{
		code: "+970",
		label: "Palestinian Territory",
		value: "Palestinian Territory",
	},
	{
		code: "+507",
		label: "Panama",
		value: "Panama",
	},
	{
		code: "+675",
		label: "Papua New Guinea",
		value: "Papua New Guinea",
	},
	{
		code: "+595",
		label: "Paraguay",
		value: "Paraguay",
	},
	{
		code: "+51",
		label: "Peru",
		value: "Peru",
	},
	{
		code: "+63",
		label: "Philippines",
		value: "Philippines",
	},
	{
		code: "+48",
		label: "Poland",
		value: "Poland",
	},
	{
		code: "+351",
		label: "Portugal",
		value: "Portugal",
	},
	{
		code: "+1 787",
		label: "Puerto Rico",
		value: "Puerto Rico",
	},
	{
		code: "+974",
		label: "Qatar",
		value: "Qatar",
	},
	{
		code: "+262",
		label: "Reunion",
		value: "Reunion",
	},
	{
		code: "+40",
		label: "Romania",
		value: "Romania",
	},
	{
		code: "+7",
		label: "Russia",
		value: "Russia",
	},
	{
		code: "+250",
		label: "Rwanda",
		value: "Rwanda",
	},
	{
		code: "+685",
		label: "Samoa",
		value: "Samoa",
	},
	{
		code: "+378",
		label: "San Marino",
		value: "San Marino",
	},
	{
		code: "+966",
		label: "Saudi Arabia",
		value: "Saudi Arabia",
	},
	{
		code: "+221",
		label: "Senegal",
		value: "Senegal",
	},
	{
		code: "+381",
		label: "Serbia",
		value: "Serbia",
	},
	{
		code: "+248",
		label: "Seychelles",
		value: "Seychelles",
	},
	{
		code: "+232",
		label: "Sierra Leone",
		value: "Sierra Leone",
	},
	{
		code: "+65",
		label: "Singapore",
		value: "Singapore",
	},
	{
		code: "+421",
		label: "Slovakia",
		value: "Slovakia",
	},
	{
		code: "+386",
		label: "Slovenia",
		value: "Slovenia",
	},
	{
		code: "+677",
		label: "Solomon Islands",
		value: "Solomon Islands",
	},
	{
		code: "+27",
		label: "South Africa",
		value: "South Africa",
	},
	{
		code: "+500",
		label: "South Georgia and the South Sandwich Islands",
		value: "South Georgia and the South Sandwich Islands",
	},
	{
		code: "+82",
		label: "South Korea",
		value: "South Korea",
	},
	{
		code: "+34",
		label: "Spain",
		value: "Spain",
	},
	{
		code: "+94",
		label: "Sri Lanka",
		value: "Sri Lanka",
	},
	{
		code: "+249",
		label: "Sudan",
		value: "Sudan",
	},
	{
		code: "+597",
		label: "Surilabel",
		value: "Surilabel",
	},
	{
		code: "+268",
		label: "Swaziland",
		value: "Swaziland",
	},
	{
		code: "+46",
		label: "Sweden",
		value: "Sweden",
	},
	{
		code: "+41",
		label: "Switzerland",
		value: "Switzerland",
	},
	{
		code: "+963",
		label: "Syria",
		value: "Syria",
	},
	{
		code: "+886",
		label: "Taiwan",
		value: "Taiwan",
	},
	{
		code: "+992",
		label: "Tajikistan",
		value: "Tajikistan",
	},
	{
		code: "+255",
		label: "Tanzania",
		value: "Tanzania",
	},
	{
		code: "+66",
		label: "Thailand",
		value: "Thailand",
	},
	{
		code: "+670",
		label: "Timor Leste",
		value: "Timor Leste",
	},
	{
		code: "+228",
		label: "Togo",
		value: "Togo",
	},
	{
		code: "+690",
		label: "Tokelau",
		value: "Tokelau",
	},
	{
		code: "+676",
		label: "Tonga",
		value: "Tonga",
	},
	{
		code: "+1 868",
		label: "Trinidad and Tobago",
		value: "Trinidad and Tobago",
	},
	{
		code: "+216",
		label: "Tunisia",
		value: "Tunisia",
	},
	{
		code: "+90",
		label: "Turkey",
		value: "Turkey",
	},
	{
		code: "+993",
		label: "Turkmenistan",
		value: "Turkmenistan",
	},
	{
		code: "+1 649",
		label: "Turks and Caicos Islands",
		value: "Turks and Caicos Islands",
	},
	{
		code: "+688",
		label: "Tuvalu",
		value: "Tuvalu",
	},
	{
		code: "+1 340",
		label: "U.S. Virgin Islands",
		value: "U.S. Virgin Islands",
	},
	{
		code: "+256",
		label: "Uganda",
		value: "Uganda",
	},
	{
		code: "+380",
		label: "Ukraine",
		value: "Ukraine",
	},
	{
		code: "+971",
		label: "United Arab Emirates",
		value: "United Arab Emirates",
	},
	{
		code: "+44",
		label: "United Kingdom",
		value: "United Kingdom",
	},
	{
		code: "+1",
		label: "United States",
		value: "United States",
	},
	{
		code: "+598",
		label: "Uruguay",
		value: "Uruguay",
	},
	{
		code: "+998",
		label: "Uzbekistan",
		value: "Uzbekistan",
	},
	{
		code: "+678",
		label: "Vanuatu",
		value: "Vanuatu",
	},
	{
		code: "+58",
		label: "Venezuela",
		value: "Venezuela",
	},
	{
		code: "+84",
		label: "Vietnam",
		value: "Vietnam",
	},
	{
		code: "+1 808",
		label: "Wake Island",
		value: "Wake Island",
	},
	{
		code: "+681",
		label: "Wallis and Futuna",
		value: "Wallis and Futuna",
	},
	{
		code: "+967",
		label: "Yemen",
		value: "Yemen",
	},
	{
		code: "+260",
		label: "Zambia",
		value: "Zambia",
	},
	{
		code: "+255",
		label: "Zanzibar",
		value: "Zanzibar",
	},
	{
		code: "+263",
		label: "Zimbabwe",
		value: "Zimbabwe",
	},
];

export const countryList: any = [
	{
		name: "Afghanistan",
		"alpha-2": "AF",
		"alpha-3": "AFG",
		"country-code": "004",
		"iso_3166-2": "ISO 3166-2:AF",
		region: "Asia",
		"sub-region": "Southern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "034",
		"intermediate-region-code": "",
		latitude: 33.93911,
		longitude: 67.709953,
	},
	{
		name: "Åland Islands",
		"alpha-2": "AX",
		"alpha-3": "ALA",
		"country-code": "248",
		"iso_3166-2": "ISO 3166-2:AX",
		region: "Europe",
		"sub-region": "Northern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "154",
		"intermediate-region-code": "",
		latitude: 60.3385485,
		longitude: 20.2712585,
	},
	{
		name: "Albania",
		"alpha-2": "AL",
		"alpha-3": "ALB",
		"country-code": "008",
		"iso_3166-2": "ISO 3166-2:AL",
		region: "Europe",
		"sub-region": "Southern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "039",
		"intermediate-region-code": "",
		latitude: 41.153332,
		longitude: 20.168331,
	},
	{
		name: "Algeria",
		"alpha-2": "DZ",
		"alpha-3": "DZA",
		"country-code": "012",
		"iso_3166-2": "ISO 3166-2:DZ",
		region: "Africa",
		"sub-region": "Northern Africa",
		"intermediate-region": "",
		"region-code": "002",
		"sub-region-code": "015",
		"intermediate-region-code": "",
		latitude: 28.033886,
		longitude: 1.659626,
	},
	{
		name: "American Samoa",
		"alpha-2": "AS",
		"alpha-3": "ASM",
		"country-code": "016",
		"iso_3166-2": "ISO 3166-2:AS",
		region: "Oceania",
		"sub-region": "Polynesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "061",
		"intermediate-region-code": "",
		latitude: -14.270972,
		longitude: -170.132217,
	},
	{
		name: "Andorra",
		"alpha-2": "AD",
		"alpha-3": "AND",
		"country-code": "020",
		"iso_3166-2": "ISO 3166-2:AD",
		region: "Europe",
		"sub-region": "Southern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "039",
		"intermediate-region-code": "",
		latitude: 42.546245,
		longitude: 1.601554,
	},
	{
		name: "Angola",
		"alpha-2": "AO",
		"alpha-3": "AGO",
		"country-code": "024",
		"iso_3166-2": "ISO 3166-2:AO",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Middle Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "017",
		latitude: -11.202692,
		longitude: 17.873887,
	},
	{
		name: "Anguilla",
		"alpha-2": "AI",
		"alpha-3": "AIA",
		"country-code": "660",
		"iso_3166-2": "ISO 3166-2:AI",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 18.220554,
		longitude: -63.068615,
	},
	{
		name: "Antarctica",
		"alpha-2": "AQ",
		"alpha-3": "ATA",
		"country-code": "010",
		"iso_3166-2": "ISO 3166-2:AQ",
		region: "",
		"sub-region": "",
		"intermediate-region": "",
		"region-code": "",
		"sub-region-code": "",
		"intermediate-region-code": "",
		latitude: -75.250973,
		longitude: -0.071389,
	},
	{
		name: "Antigua and Barbuda",
		"alpha-2": "AG",
		"alpha-3": "ATG",
		"country-code": "028",
		"iso_3166-2": "ISO 3166-2:AG",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 17.060816,
		longitude: -61.796428,
	},
	{
		name: "Argentina",
		"alpha-2": "AR",
		"alpha-3": "ARG",
		"country-code": "032",
		"iso_3166-2": "ISO 3166-2:AR",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "South America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "005",
		latitude: -38.416097,
		longitude: -63.616672,
	},
	{
		name: "Armenia",
		"alpha-2": "AM",
		"alpha-3": "ARM",
		"country-code": "051",
		"iso_3166-2": "ISO 3166-2:AM",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 40.069099,
		longitude: 45.038189,
	},
	{
		name: "Aruba",
		"alpha-2": "AW",
		"alpha-3": "ABW",
		"country-code": "533",
		"iso_3166-2": "ISO 3166-2:AW",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 12.52111,
		longitude: -69.968338,
	},
	{
		name: "Australia",
		"alpha-2": "AU",
		"alpha-3": "AUS",
		"country-code": "036",
		"iso_3166-2": "ISO 3166-2:AU",
		region: "Oceania",
		"sub-region": "Australia and New Zealand",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "053",
		"intermediate-region-code": "",
		latitude: -25.274398,
		longitude: 133.775136,
	},
	{
		name: "Austria",
		"alpha-2": "AT",
		"alpha-3": "AUT",
		"country-code": "040",
		"iso_3166-2": "ISO 3166-2:AT",
		region: "Europe",
		"sub-region": "Western Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "155",
		"intermediate-region-code": "",
		latitude: 47.516231,
		longitude: 14.550072,
	},
	{
		name: "Azerbaijan",
		"alpha-2": "AZ",
		"alpha-3": "AZE",
		"country-code": "031",
		"iso_3166-2": "ISO 3166-2:AZ",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 40.143105,
		longitude: 47.576927,
	},
	{
		name: "Bahamas",
		"alpha-2": "BS",
		"alpha-3": "BHS",
		"country-code": "044",
		"iso_3166-2": "ISO 3166-2:BS",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 25.03428,
		longitude: -77.39628,
	},
	{
		name: "Bahrain",
		"alpha-2": "BH",
		"alpha-3": "BHR",
		"country-code": "048",
		"iso_3166-2": "ISO 3166-2:BH",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 25.930414,
		longitude: 50.637772,
	},
	{
		name: "Bangladesh",
		"alpha-2": "BD",
		"alpha-3": "BGD",
		"country-code": "050",
		"iso_3166-2": "ISO 3166-2:BD",
		region: "Asia",
		"sub-region": "Southern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "034",
		"intermediate-region-code": "",
		latitude: 23.684994,
		longitude: 90.356331,
	},
	{
		name: "Barbados",
		"alpha-2": "BB",
		"alpha-3": "BRB",
		"country-code": "052",
		"iso_3166-2": "ISO 3166-2:BB",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 13.193887,
		longitude: -59.543198,
	},
	{
		name: "Belarus",
		"alpha-2": "BY",
		"alpha-3": "BLR",
		"country-code": "112",
		"iso_3166-2": "ISO 3166-2:BY",
		region: "Europe",
		"sub-region": "Eastern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "151",
		"intermediate-region-code": "",
		latitude: 53.709807,
		longitude: 27.953389,
	},
	{
		name: "Belgium",
		"alpha-2": "BE",
		"alpha-3": "BEL",
		"country-code": "056",
		"iso_3166-2": "ISO 3166-2:BE",
		region: "Europe",
		"sub-region": "Western Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "155",
		"intermediate-region-code": "",
		latitude: 50.503887,
		longitude: 4.469936,
	},
	{
		name: "Belize",
		"alpha-2": "BZ",
		"alpha-3": "BLZ",
		"country-code": "084",
		"iso_3166-2": "ISO 3166-2:BZ",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Central America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "013",
		latitude: 17.189877,
		longitude: -88.49765,
	},
	{
		name: "Benin",
		"alpha-2": "BJ",
		"alpha-3": "BEN",
		"country-code": "204",
		"iso_3166-2": "ISO 3166-2:BJ",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: 9.30769,
		longitude: 2.315834,
	},
	{
		name: "Bermuda",
		"alpha-2": "BM",
		"alpha-3": "BMU",
		"country-code": "060",
		"iso_3166-2": "ISO 3166-2:BM",
		region: "Americas",
		"sub-region": "Northern America",
		"intermediate-region": "",
		"region-code": "019",
		"sub-region-code": "021",
		"intermediate-region-code": "",
		latitude: 32.321384,
		longitude: -64.75737,
	},
	{
		name: "Bhutan",
		"alpha-2": "BT",
		"alpha-3": "BTN",
		"country-code": "064",
		"iso_3166-2": "ISO 3166-2:BT",
		region: "Asia",
		"sub-region": "Southern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "034",
		"intermediate-region-code": "",
		latitude: 27.514162,
		longitude: 90.433601,
	},
	{
		name: "Bolivia (Plurinational State of)",
		"alpha-2": "BO",
		"alpha-3": "BOL",
		"country-code": "068",
		"iso_3166-2": "ISO 3166-2:BO",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "South America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "005",
		latitude: -16.290154,
		longitude: -63.588653,
	},
	{
		name: "Bonaire, Sint Eustatius and Saba",
		"alpha-2": "BQ",
		"alpha-3": "BES",
		"country-code": "535",
		"iso_3166-2": "ISO 3166-2:BQ",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 12.1783611,
		longitude: -68.2385339,
	},
	{
		name: "Bosnia and Herzegovina",
		"alpha-2": "BA",
		"alpha-3": "BIH",
		"country-code": "070",
		"iso_3166-2": "ISO 3166-2:BA",
		region: "Europe",
		"sub-region": "Southern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "039",
		"intermediate-region-code": "",
		latitude: 43.915886,
		longitude: 17.679076,
	},
	{
		name: "Botswana",
		"alpha-2": "BW",
		"alpha-3": "BWA",
		"country-code": "072",
		"iso_3166-2": "ISO 3166-2:BW",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Southern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "018",
		latitude: -22.328474,
		longitude: 24.684866,
	},
	{
		name: "Bouvet Island",
		"alpha-2": "BV",
		"alpha-3": "BVT",
		"country-code": "074",
		"iso_3166-2": "ISO 3166-2:BV",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "South America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "005",
		latitude: -54.423199,
		longitude: 3.413194,
	},
	{
		name: "Brazil",
		"alpha-2": "BR",
		"alpha-3": "BRA",
		"country-code": "076",
		"iso_3166-2": "ISO 3166-2:BR",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "South America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "005",
		latitude: -14.235004,
		longitude: -51.92528,
	},
	{
		name: "British Indian Ocean Territory",
		"alpha-2": "IO",
		"alpha-3": "IOT",
		"country-code": "086",
		"iso_3166-2": "ISO 3166-2:IO",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: -6.343194,
		longitude: 71.876519,
	},
	{
		name: "Brunei Darussalam",
		"alpha-2": "BN",
		"alpha-3": "BRN",
		"country-code": "096",
		"iso_3166-2": "ISO 3166-2:BN",
		region: "Asia",
		"sub-region": "South-eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "035",
		"intermediate-region-code": "",
		latitude: 4.535277,
		longitude: 114.727669,
	},
	{
		name: "Bulgaria",
		"alpha-2": "BG",
		"alpha-3": "BGR",
		"country-code": "100",
		"iso_3166-2": "ISO 3166-2:BG",
		region: "Europe",
		"sub-region": "Eastern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "151",
		"intermediate-region-code": "",
		latitude: 42.733883,
		longitude: 25.48583,
	},
	{
		name: "Burkina Faso",
		"alpha-2": "BF",
		"alpha-3": "BFA",
		"country-code": "854",
		"iso_3166-2": "ISO 3166-2:BF",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: 12.238333,
		longitude: -1.561593,
	},
	{
		name: "Burundi",
		"alpha-2": "BI",
		"alpha-3": "BDI",
		"country-code": "108",
		"iso_3166-2": "ISO 3166-2:BI",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: -3.373056,
		longitude: 29.918886,
	},
	{
		name: "Cabo Verde",
		"alpha-2": "CV",
		"alpha-3": "CPV",
		"country-code": "132",
		"iso_3166-2": "ISO 3166-2:CV",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: 16.002082,
		longitude: -24.013197,
	},
	{
		name: "Cambodia",
		"alpha-2": "KH",
		"alpha-3": "KHM",
		"country-code": "116",
		"iso_3166-2": "ISO 3166-2:KH",
		region: "Asia",
		"sub-region": "South-eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "035",
		"intermediate-region-code": "",
		latitude: 12.565679,
		longitude: 104.990963,
	},
	{
		name: "Cameroon",
		"alpha-2": "CM",
		"alpha-3": "CMR",
		"country-code": "120",
		"iso_3166-2": "ISO 3166-2:CM",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Middle Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "017",
		latitude: 7.369722,
		longitude: 12.354722,
	},
	{
		name: "Canada",
		"alpha-2": "CA",
		"alpha-3": "CAN",
		"country-code": "124",
		"iso_3166-2": "ISO 3166-2:CA",
		region: "Americas",
		"sub-region": "Northern America",
		"intermediate-region": "",
		"region-code": "019",
		"sub-region-code": "021",
		"intermediate-region-code": "",
		latitude: 56.130366,
		longitude: -106.346771,
	},
	{
		name: "Cayman Islands",
		"alpha-2": "KY",
		"alpha-3": "CYM",
		"country-code": "136",
		"iso_3166-2": "ISO 3166-2:KY",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 19.513469,
		longitude: -80.566956,
	},
	{
		name: "Central African Republic",
		"alpha-2": "CF",
		"alpha-3": "CAF",
		"country-code": "140",
		"iso_3166-2": "ISO 3166-2:CF",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Middle Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "017",
		latitude: 6.611111,
		longitude: 20.939444,
	},
	{
		name: "Chad",
		"alpha-2": "TD",
		"alpha-3": "TCD",
		"country-code": "148",
		"iso_3166-2": "ISO 3166-2:TD",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Middle Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "017",
		latitude: 15.454166,
		longitude: 18.732207,
	},
	{
		name: "Chile",
		"alpha-2": "CL",
		"alpha-3": "CHL",
		"country-code": "152",
		"iso_3166-2": "ISO 3166-2:CL",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "South America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "005",
		latitude: -35.675147,
		longitude: -71.542969,
	},
	{
		name: "China",
		"alpha-2": "CN",
		"alpha-3": "CHN",
		"country-code": "156",
		"iso_3166-2": "ISO 3166-2:CN",
		region: "Asia",
		"sub-region": "Eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "030",
		"intermediate-region-code": "",
		latitude: 35.86166,
		longitude: 104.195397,
	},
	{
		name: "Christmas Island",
		"alpha-2": "CX",
		"alpha-3": "CXR",
		"country-code": "162",
		"iso_3166-2": "ISO 3166-2:CX",
		region: "Oceania",
		"sub-region": "Australia and New Zealand",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "053",
		"intermediate-region-code": "",
		latitude: -10.447525,
		longitude: 105.690449,
	},
	{
		name: "Cocos (Keeling) Islands",
		"alpha-2": "CC",
		"alpha-3": "CCK",
		"country-code": "166",
		"iso_3166-2": "ISO 3166-2:CC",
		region: "Oceania",
		"sub-region": "Australia and New Zealand",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "053",
		"intermediate-region-code": "",
		latitude: -12.164165,
		longitude: 96.870956,
	},
	{
		name: "Colombia",
		"alpha-2": "CO",
		"alpha-3": "COL",
		"country-code": "170",
		"iso_3166-2": "ISO 3166-2:CO",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "South America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "005",
		latitude: 4.570868,
		longitude: -74.297333,
	},
	{
		name: "Comoros",
		"alpha-2": "KM",
		"alpha-3": "COM",
		"country-code": "174",
		"iso_3166-2": "ISO 3166-2:KM",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: -11.875001,
		longitude: 43.872219,
	},
	{
		name: "Congo",
		"alpha-2": "CG",
		"alpha-3": "COG",
		"country-code": "178",
		"iso_3166-2": "ISO 3166-2:CG",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Middle Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "017",
		latitude: -0.228021,
		longitude: 15.827659,
	},
	{
		name: "Congo, Democratic Republic of the",
		"alpha-2": "CD",
		"alpha-3": "COD",
		"country-code": "180",
		"iso_3166-2": "ISO 3166-2:CD",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Middle Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "017",
		latitude: -4.038333,
		longitude: 21.758664,
	},
	{
		name: "Cook Islands",
		"alpha-2": "CK",
		"alpha-3": "COK",
		"country-code": "184",
		"iso_3166-2": "ISO 3166-2:CK",
		region: "Oceania",
		"sub-region": "Polynesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "061",
		"intermediate-region-code": "",
		latitude: -21.236736,
		longitude: -159.777671,
	},
	{
		name: "Costa Rica",
		"alpha-2": "CR",
		"alpha-3": "CRI",
		"country-code": "188",
		"iso_3166-2": "ISO 3166-2:CR",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Central America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "013",
		latitude: 9.748917,
		longitude: -83.753428,
	},
	{
		name: "Côte d'Ivoire",
		"alpha-2": "CI",
		"alpha-3": "CIV",
		"country-code": "384",
		"iso_3166-2": "ISO 3166-2:CI",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: 7.539989,
		longitude: -5.54708,
	},
	{
		name: "Croatia",
		"alpha-2": "HR",
		"alpha-3": "HRV",
		"country-code": "191",
		"iso_3166-2": "ISO 3166-2:HR",
		region: "Europe",
		"sub-region": "Southern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "039",
		"intermediate-region-code": "",
		latitude: 45.1,
		longitude: 15.2,
	},
	{
		name: "Cuba",
		"alpha-2": "CU",
		"alpha-3": "CUB",
		"country-code": "192",
		"iso_3166-2": "ISO 3166-2:CU",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 21.521757,
		longitude: -77.781167,
	},
	{
		name: "Curaçao",
		"alpha-2": "CW",
		"alpha-3": "CUW",
		"country-code": "531",
		"iso_3166-2": "ISO 3166-2:CW",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 12.16957,
		longitude: -68.99002,
	},
	{
		name: "Cyprus",
		"alpha-2": "CY",
		"alpha-3": "CYP",
		"country-code": "196",
		"iso_3166-2": "ISO 3166-2:CY",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 35.126413,
		longitude: 33.429859,
	},
	{
		name: "Czechia",
		"alpha-2": "CZ",
		"alpha-3": "CZE",
		"country-code": "203",
		"iso_3166-2": "ISO 3166-2:CZ",
		region: "Europe",
		"sub-region": "Eastern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "151",
		"intermediate-region-code": "",
		latitude: 49.817492,
		longitude: 15.472962,
	},
	{
		name: "Denmark",
		"alpha-2": "DK",
		"alpha-3": "DNK",
		"country-code": "208",
		"iso_3166-2": "ISO 3166-2:DK",
		region: "Europe",
		"sub-region": "Northern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "154",
		"intermediate-region-code": "",
		latitude: 56.26392,
		longitude: 9.501785,
	},
	{
		name: "Djibouti",
		"alpha-2": "DJ",
		"alpha-3": "DJI",
		"country-code": "262",
		"iso_3166-2": "ISO 3166-2:DJ",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: 11.825138,
		longitude: 42.590275,
	},
	{
		name: "Dominica",
		"alpha-2": "DM",
		"alpha-3": "DMA",
		"country-code": "212",
		"iso_3166-2": "ISO 3166-2:DM",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 15.414999,
		longitude: -61.370976,
	},
	{
		name: "Dominican Republic",
		"alpha-2": "DO",
		"alpha-3": "DOM",
		"country-code": "214",
		"iso_3166-2": "ISO 3166-2:DO",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 18.735693,
		longitude: -70.162651,
	},
	{
		name: "Ecuador",
		"alpha-2": "EC",
		"alpha-3": "ECU",
		"country-code": "218",
		"iso_3166-2": "ISO 3166-2:EC",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "South America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "005",
		latitude: -1.831239,
		longitude: -78.183406,
	},
	{
		name: "Egypt",
		"alpha-2": "EG",
		"alpha-3": "EGY",
		"country-code": "818",
		"iso_3166-2": "ISO 3166-2:EG",
		region: "Africa",
		"sub-region": "Northern Africa",
		"intermediate-region": "",
		"region-code": "002",
		"sub-region-code": "015",
		"intermediate-region-code": "",
		latitude: 26.820553,
		longitude: 30.802498,
	},
	{
		name: "El Salvador",
		"alpha-2": "SV",
		"alpha-3": "SLV",
		"country-code": "222",
		"iso_3166-2": "ISO 3166-2:SV",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Central America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "013",
		latitude: 13.794185,
		longitude: -88.89653,
	},
	{
		name: "Equatorial Guinea",
		"alpha-2": "GQ",
		"alpha-3": "GNQ",
		"country-code": "226",
		"iso_3166-2": "ISO 3166-2:GQ",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Middle Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "017",
		latitude: 1.650801,
		longitude: 10.267895,
	},
	{
		name: "Eritrea",
		"alpha-2": "ER",
		"alpha-3": "ERI",
		"country-code": "232",
		"iso_3166-2": "ISO 3166-2:ER",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: 15.179384,
		longitude: 39.782334,
	},
	{
		name: "Estonia",
		"alpha-2": "EE",
		"alpha-3": "EST",
		"country-code": "233",
		"iso_3166-2": "ISO 3166-2:EE",
		region: "Europe",
		"sub-region": "Northern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "154",
		"intermediate-region-code": "",
		latitude: 58.595272,
		longitude: 25.013607,
	},
	{
		name: "Eswatini",
		"alpha-2": "SZ",
		"alpha-3": "SWZ",
		"country-code": "748",
		"iso_3166-2": "ISO 3166-2:SZ",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Southern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "018",
		latitude: -26.522503,
		longitude: 31.465866,
	},
	{
		name: "Ethiopia",
		"alpha-2": "ET",
		"alpha-3": "ETH",
		"country-code": "231",
		"iso_3166-2": "ISO 3166-2:ET",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: 9.145,
		longitude: 40.489673,
	},
	{
		name: "Falkland Islands (Malvinas)",
		"alpha-2": "FK",
		"alpha-3": "FLK",
		"country-code": "238",
		"iso_3166-2": "ISO 3166-2:FK",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "South America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "005",
		latitude: -51.796253,
		longitude: -59.523613,
	},
	{
		name: "Faroe Islands",
		"alpha-2": "FO",
		"alpha-3": "FRO",
		"country-code": "234",
		"iso_3166-2": "ISO 3166-2:FO",
		region: "Europe",
		"sub-region": "Northern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "154",
		"intermediate-region-code": "",
		latitude: 61.892635,
		longitude: -6.911806,
	},
	{
		name: "Fiji",
		"alpha-2": "FJ",
		"alpha-3": "FJI",
		"country-code": "242",
		"iso_3166-2": "ISO 3166-2:FJ",
		region: "Oceania",
		"sub-region": "Melanesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "054",
		"intermediate-region-code": "",
		latitude: -16.578193,
		longitude: 179.414413,
	},
	{
		name: "Finland",
		"alpha-2": "FI",
		"alpha-3": "FIN",
		"country-code": "246",
		"iso_3166-2": "ISO 3166-2:FI",
		region: "Europe",
		"sub-region": "Northern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "154",
		"intermediate-region-code": "",
		latitude: 61.92411,
		longitude: 25.748151,
	},
	{
		name: "France",
		"alpha-2": "FR",
		"alpha-3": "FRA",
		"country-code": "250",
		"iso_3166-2": "ISO 3166-2:FR",
		region: "Europe",
		"sub-region": "Western Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "155",
		"intermediate-region-code": "",
		latitude: 46.227638,
		longitude: 2.213749,
	},
	{
		name: "French Guiana",
		"alpha-2": "GF",
		"alpha-3": "GUF",
		"country-code": "254",
		"iso_3166-2": "ISO 3166-2:GF",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "South America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "005",
		latitude: 3.933889,
		longitude: -53.125782,
	},
	{
		name: "French Polynesia",
		"alpha-2": "PF",
		"alpha-3": "PYF",
		"country-code": "258",
		"iso_3166-2": "ISO 3166-2:PF",
		region: "Oceania",
		"sub-region": "Polynesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "061",
		"intermediate-region-code": "",
		latitude: -17.679742,
		longitude: -149.406843,
	},
	{
		name: "French Southern Territories",
		"alpha-2": "TF",
		"alpha-3": "ATF",
		"country-code": "260",
		"iso_3166-2": "ISO 3166-2:TF",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: -49.280366,
		longitude: 69.348557,
	},
	{
		name: "Gabon",
		"alpha-2": "GA",
		"alpha-3": "GAB",
		"country-code": "266",
		"iso_3166-2": "ISO 3166-2:GA",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Middle Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "017",
		latitude: -0.803689,
		longitude: 11.609444,
	},
	{
		name: "Gambia",
		"alpha-2": "GM",
		"alpha-3": "GMB",
		"country-code": "270",
		"iso_3166-2": "ISO 3166-2:GM",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: 13.443182,
		longitude: -15.310139,
	},
	{
		name: "Georgia",
		"alpha-2": "GE",
		"alpha-3": "GEO",
		"country-code": "268",
		"iso_3166-2": "ISO 3166-2:GE",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 42.315407,
		longitude: 43.356892,
	},
	{
		name: "Germany",
		"alpha-2": "DE",
		"alpha-3": "DEU",
		"country-code": "276",
		"iso_3166-2": "ISO 3166-2:DE",
		region: "Europe",
		"sub-region": "Western Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "155",
		"intermediate-region-code": "",
		latitude: 51.165691,
		longitude: 10.451526,
	},
	{
		name: "Ghana",
		"alpha-2": "GH",
		"alpha-3": "GHA",
		"country-code": "288",
		"iso_3166-2": "ISO 3166-2:GH",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: 7.946527,
		longitude: -1.023194,
	},
	{
		name: "Gibraltar",
		"alpha-2": "GI",
		"alpha-3": "GIB",
		"country-code": "292",
		"iso_3166-2": "ISO 3166-2:GI",
		region: "Europe",
		"sub-region": "Southern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "039",
		"intermediate-region-code": "",
		latitude: 36.137741,
		longitude: -5.345374,
	},
	{
		name: "Greece",
		"alpha-2": "GR",
		"alpha-3": "GRC",
		"country-code": "300",
		"iso_3166-2": "ISO 3166-2:GR",
		region: "Europe",
		"sub-region": "Southern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "039",
		"intermediate-region-code": "",
		latitude: 39.074208,
		longitude: 21.824312,
	},
	{
		name: "Greenland",
		"alpha-2": "GL",
		"alpha-3": "GRL",
		"country-code": "304",
		"iso_3166-2": "ISO 3166-2:GL",
		region: "Americas",
		"sub-region": "Northern America",
		"intermediate-region": "",
		"region-code": "019",
		"sub-region-code": "021",
		"intermediate-region-code": "",
		latitude: 71.706936,
		longitude: -42.604303,
	},
	{
		name: "Grenada",
		"alpha-2": "GD",
		"alpha-3": "GRD",
		"country-code": "308",
		"iso_3166-2": "ISO 3166-2:GD",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 12.262776,
		longitude: -61.604171,
	},
	{
		name: "Guadeloupe",
		"alpha-2": "GP",
		"alpha-3": "GLP",
		"country-code": "312",
		"iso_3166-2": "ISO 3166-2:GP",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 16.995971,
		longitude: -62.067641,
	},
	{
		name: "Guam",
		"alpha-2": "GU",
		"alpha-3": "GUM",
		"country-code": "316",
		"iso_3166-2": "ISO 3166-2:GU",
		region: "Oceania",
		"sub-region": "Micronesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "057",
		"intermediate-region-code": "",
		latitude: 13.444304,
		longitude: 144.793731,
	},
	{
		name: "Guatemala",
		"alpha-2": "GT",
		"alpha-3": "GTM",
		"country-code": "320",
		"iso_3166-2": "ISO 3166-2:GT",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Central America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "013",
		latitude: 15.783471,
		longitude: -90.230759,
	},
	{
		name: "Guernsey",
		"alpha-2": "GG",
		"alpha-3": "GGY",
		"country-code": "831",
		"iso_3166-2": "ISO 3166-2:GG",
		region: "Europe",
		"sub-region": "Northern Europe",
		"intermediate-region": "Channel Islands",
		"region-code": "150",
		"sub-region-code": "154",
		"intermediate-region-code": "830",
		latitude: 49.465691,
		longitude: -2.585278,
	},
	{
		name: "Guinea",
		"alpha-2": "GN",
		"alpha-3": "GIN",
		"country-code": "324",
		"iso_3166-2": "ISO 3166-2:GN",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: 9.945587,
		longitude: -9.696645,
	},
	{
		name: "Guinea-Bissau",
		"alpha-2": "GW",
		"alpha-3": "GNB",
		"country-code": "624",
		"iso_3166-2": "ISO 3166-2:GW",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: 11.803749,
		longitude: -15.180413,
	},
	{
		name: "Guyana",
		"alpha-2": "GY",
		"alpha-3": "GUY",
		"country-code": "328",
		"iso_3166-2": "ISO 3166-2:GY",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "South America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "005",
		latitude: 4.860416,
		longitude: -58.93018,
	},
	{
		name: "Haiti",
		"alpha-2": "HT",
		"alpha-3": "HTI",
		"country-code": "332",
		"iso_3166-2": "ISO 3166-2:HT",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 18.971187,
		longitude: -72.285215,
	},
	{
		name: "Heard Island and McDonald Islands",
		"alpha-2": "HM",
		"alpha-3": "HMD",
		"country-code": "334",
		"iso_3166-2": "ISO 3166-2:HM",
		region: "Oceania",
		"sub-region": "Australia and New Zealand",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "053",
		"intermediate-region-code": "",
		latitude: -53.08181,
		longitude: 73.504158,
	},
	{
		name: "Holy See",
		"alpha-2": "VA",
		"alpha-3": "VAT",
		"country-code": "336",
		"iso_3166-2": "ISO 3166-2:VA",
		region: "Europe",
		"sub-region": "Southern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "039",
		"intermediate-region-code": "",
		latitude: 41.902916,
		longitude: 12.453389,
	},
	{
		name: "Honduras",
		"alpha-2": "HN",
		"alpha-3": "HND",
		"country-code": "340",
		"iso_3166-2": "ISO 3166-2:HN",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Central America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "013",
		latitude: 15.199999,
		longitude: -86.241905,
	},
	{
		name: "Hong Kong",
		"alpha-2": "HK",
		"alpha-3": "HKG",
		"country-code": "344",
		"iso_3166-2": "ISO 3166-2:HK",
		region: "Asia",
		"sub-region": "Eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "030",
		"intermediate-region-code": "",
		latitude: 22.396428,
		longitude: 114.109497,
	},
	{
		name: "Hungary",
		"alpha-2": "HU",
		"alpha-3": "HUN",
		"country-code": "348",
		"iso_3166-2": "ISO 3166-2:HU",
		region: "Europe",
		"sub-region": "Eastern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "151",
		"intermediate-region-code": "",
		latitude: 47.162494,
		longitude: 19.503304,
	},
	{
		name: "Iceland",
		"alpha-2": "IS",
		"alpha-3": "ISL",
		"country-code": "352",
		"iso_3166-2": "ISO 3166-2:IS",
		region: "Europe",
		"sub-region": "Northern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "154",
		"intermediate-region-code": "",
		latitude: 64.963051,
		longitude: -19.020835,
	},
	{
		name: "India",
		"alpha-2": "IN",
		"alpha-3": "IND",
		"country-code": "356",
		"iso_3166-2": "ISO 3166-2:IN",
		region: "Asia",
		"sub-region": "Southern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "034",
		"intermediate-region-code": "",
		latitude: 20.593684,
		longitude: 78.96288,
	},
	{
		name: "Indonesia",
		"alpha-2": "ID",
		"alpha-3": "IDN",
		"country-code": "360",
		"iso_3166-2": "ISO 3166-2:ID",
		region: "Asia",
		"sub-region": "South-eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "035",
		"intermediate-region-code": "",
		latitude: -0.789275,
		longitude: 113.921327,
	},
	{
		name: "Iran (Islamic Republic of)",
		"alpha-2": "IR",
		"alpha-3": "IRN",
		"country-code": "364",
		"iso_3166-2": "ISO 3166-2:IR",
		region: "Asia",
		"sub-region": "Southern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "034",
		"intermediate-region-code": "",
		latitude: 32.427908,
		longitude: 53.688046,
	},
	{
		name: "Iraq",
		"alpha-2": "IQ",
		"alpha-3": "IRQ",
		"country-code": "368",
		"iso_3166-2": "ISO 3166-2:IQ",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 33.223191,
		longitude: 43.679291,
	},
	{
		name: "Ireland",
		"alpha-2": "IE",
		"alpha-3": "IRL",
		"country-code": "372",
		"iso_3166-2": "ISO 3166-2:IE",
		region: "Europe",
		"sub-region": "Northern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "154",
		"intermediate-region-code": "",
		latitude: 53.41291,
		longitude: -8.24389,
	},
	{
		name: "Isle of Man",
		"alpha-2": "IM",
		"alpha-3": "IMN",
		"country-code": "833",
		"iso_3166-2": "ISO 3166-2:IM",
		region: "Europe",
		"sub-region": "Northern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "154",
		"intermediate-region-code": "",
		latitude: 54.236107,
		longitude: -4.548056,
	},
	{
		name: "Israel",
		"alpha-2": "IL",
		"alpha-3": "ISR",
		"country-code": "376",
		"iso_3166-2": "ISO 3166-2:IL",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 31.046051,
		longitude: 34.851612,
	},
	{
		name: "Italy",
		"alpha-2": "IT",
		"alpha-3": "ITA",
		"country-code": "380",
		"iso_3166-2": "ISO 3166-2:IT",
		region: "Europe",
		"sub-region": "Southern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "039",
		"intermediate-region-code": "",
		latitude: 41.87194,
		longitude: 12.56738,
	},
	{
		name: "Jamaica",
		"alpha-2": "JM",
		"alpha-3": "JAM",
		"country-code": "388",
		"iso_3166-2": "ISO 3166-2:JM",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 18.109581,
		longitude: -77.297508,
	},
	{
		name: "Japan",
		"alpha-2": "JP",
		"alpha-3": "JPN",
		"country-code": "392",
		"iso_3166-2": "ISO 3166-2:JP",
		region: "Asia",
		"sub-region": "Eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "030",
		"intermediate-region-code": "",
		latitude: 36.204824,
		longitude: 138.252924,
	},
	{
		name: "Jersey",
		"alpha-2": "JE",
		"alpha-3": "JEY",
		"country-code": "832",
		"iso_3166-2": "ISO 3166-2:JE",
		region: "Europe",
		"sub-region": "Northern Europe",
		"intermediate-region": "Channel Islands",
		"region-code": "150",
		"sub-region-code": "154",
		"intermediate-region-code": "830",
		latitude: 49.214439,
		longitude: -2.13125,
	},
	{
		name: "Jordan",
		"alpha-2": "JO",
		"alpha-3": "JOR",
		"country-code": "400",
		"iso_3166-2": "ISO 3166-2:JO",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 30.585164,
		longitude: 36.238414,
	},
	{
		name: "Kazakhstan",
		"alpha-2": "KZ",
		"alpha-3": "KAZ",
		"country-code": "398",
		"iso_3166-2": "ISO 3166-2:KZ",
		region: "Asia",
		"sub-region": "Central Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "143",
		"intermediate-region-code": "",
		latitude: 48.019573,
		longitude: 66.923684,
	},
	{
		name: "Kenya",
		"alpha-2": "KE",
		"alpha-3": "KEN",
		"country-code": "404",
		"iso_3166-2": "ISO 3166-2:KE",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: -0.023559,
		longitude: 37.906193,
	},
	{
		name: "Kiribati",
		"alpha-2": "KI",
		"alpha-3": "KIR",
		"country-code": "296",
		"iso_3166-2": "ISO 3166-2:KI",
		region: "Oceania",
		"sub-region": "Micronesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "057",
		"intermediate-region-code": "",
		latitude: -3.370417,
		longitude: -168.734039,
	},
	{
		name: "Korea (Democratic People's Republic of)",
		"alpha-2": "KP",
		"alpha-3": "PRK",
		"country-code": "408",
		"iso_3166-2": "ISO 3166-2:KP",
		region: "Asia",
		"sub-region": "Eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "030",
		"intermediate-region-code": "",
		latitude: 40.339852,
		longitude: 127.510093,
	},
	{
		name: "Korea, Republic of",
		"alpha-2": "KR",
		"alpha-3": "KOR",
		"country-code": "410",
		"iso_3166-2": "ISO 3166-2:KR",
		region: "Asia",
		"sub-region": "Eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "030",
		"intermediate-region-code": "",
		latitude: 35.907757,
		longitude: 127.766922,
	},
	{
		name: "Kuwait",
		"alpha-2": "KW",
		"alpha-3": "KWT",
		"country-code": "414",
		"iso_3166-2": "ISO 3166-2:KW",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 29.31166,
		longitude: 47.481766,
	},
	{
		name: "Kyrgyzstan",
		"alpha-2": "KG",
		"alpha-3": "KGZ",
		"country-code": "417",
		"iso_3166-2": "ISO 3166-2:KG",
		region: "Asia",
		"sub-region": "Central Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "143",
		"intermediate-region-code": "",
		latitude: 41.20438,
		longitude: 74.766098,
	},
	{
		name: "Lao People's Democratic Republic",
		"alpha-2": "LA",
		"alpha-3": "LAO",
		"country-code": "418",
		"iso_3166-2": "ISO 3166-2:LA",
		region: "Asia",
		"sub-region": "South-eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "035",
		"intermediate-region-code": "",
		latitude: 19.85627,
		longitude: 102.495496,
	},
	{
		name: "Latvia",
		"alpha-2": "LV",
		"alpha-3": "LVA",
		"country-code": "428",
		"iso_3166-2": "ISO 3166-2:LV",
		region: "Europe",
		"sub-region": "Northern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "154",
		"intermediate-region-code": "",
		latitude: 56.879635,
		longitude: 24.603189,
	},
	{
		name: "Lebanon",
		"alpha-2": "LB",
		"alpha-3": "LBN",
		"country-code": "422",
		"iso_3166-2": "ISO 3166-2:LB",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 33.854721,
		longitude: 35.862285,
	},
	{
		name: "Lesotho",
		"alpha-2": "LS",
		"alpha-3": "LSO",
		"country-code": "426",
		"iso_3166-2": "ISO 3166-2:LS",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Southern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "018",
		latitude: -29.609988,
		longitude: 28.233608,
	},
	{
		name: "Liberia",
		"alpha-2": "LR",
		"alpha-3": "LBR",
		"country-code": "430",
		"iso_3166-2": "ISO 3166-2:LR",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: 6.428055,
		longitude: -9.429499,
	},
	{
		name: "Libya",
		"alpha-2": "LY",
		"alpha-3": "LBY",
		"country-code": "434",
		"iso_3166-2": "ISO 3166-2:LY",
		region: "Africa",
		"sub-region": "Northern Africa",
		"intermediate-region": "",
		"region-code": "002",
		"sub-region-code": "015",
		"intermediate-region-code": "",
		latitude: 26.3351,
		longitude: 17.228331,
	},
	{
		name: "Liechtenstein",
		"alpha-2": "LI",
		"alpha-3": "LIE",
		"country-code": "438",
		"iso_3166-2": "ISO 3166-2:LI",
		region: "Europe",
		"sub-region": "Western Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "155",
		"intermediate-region-code": "",
		latitude: 47.166,
		longitude: 9.555373,
	},
	{
		name: "Lithuania",
		"alpha-2": "LT",
		"alpha-3": "LTU",
		"country-code": "440",
		"iso_3166-2": "ISO 3166-2:LT",
		region: "Europe",
		"sub-region": "Northern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "154",
		"intermediate-region-code": "",
		latitude: 55.169438,
		longitude: 23.881275,
	},
	{
		name: "Luxembourg",
		"alpha-2": "LU",
		"alpha-3": "LUX",
		"country-code": "442",
		"iso_3166-2": "ISO 3166-2:LU",
		region: "Europe",
		"sub-region": "Western Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "155",
		"intermediate-region-code": "",
		latitude: 49.815273,
		longitude: 6.129583,
	},
	{
		name: "Macao",
		"alpha-2": "MO",
		"alpha-3": "MAC",
		"country-code": "446",
		"iso_3166-2": "ISO 3166-2:MO",
		region: "Asia",
		"sub-region": "Eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "030",
		"intermediate-region-code": "",
		latitude: 22.198745,
		longitude: 113.543873,
	},
	{
		name: "Madagascar",
		"alpha-2": "MG",
		"alpha-3": "MDG",
		"country-code": "450",
		"iso_3166-2": "ISO 3166-2:MG",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: -18.766947,
		longitude: 46.869107,
	},
	{
		name: "Malawi",
		"alpha-2": "MW",
		"alpha-3": "MWI",
		"country-code": "454",
		"iso_3166-2": "ISO 3166-2:MW",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: -13.254308,
		longitude: 34.301525,
	},
	{
		name: "Malaysia",
		"alpha-2": "MY",
		"alpha-3": "MYS",
		"country-code": "458",
		"iso_3166-2": "ISO 3166-2:MY",
		region: "Asia",
		"sub-region": "South-eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "035",
		"intermediate-region-code": "",
		latitude: 4.210484,
		longitude: 101.975766,
	},
	{
		name: "Maldives",
		"alpha-2": "MV",
		"alpha-3": "MDV",
		"country-code": "462",
		"iso_3166-2": "ISO 3166-2:MV",
		region: "Asia",
		"sub-region": "Southern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "034",
		"intermediate-region-code": "",
		latitude: 3.202778,
		longitude: 73.22068,
	},
	{
		name: "Mali",
		"alpha-2": "ML",
		"alpha-3": "MLI",
		"country-code": "466",
		"iso_3166-2": "ISO 3166-2:ML",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: 17.570692,
		longitude: -3.996166,
	},
	{
		name: "Malta",
		"alpha-2": "MT",
		"alpha-3": "MLT",
		"country-code": "470",
		"iso_3166-2": "ISO 3166-2:MT",
		region: "Europe",
		"sub-region": "Southern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "039",
		"intermediate-region-code": "",
		latitude: 35.937496,
		longitude: 14.375416,
	},
	{
		name: "Marshall Islands",
		"alpha-2": "MH",
		"alpha-3": "MHL",
		"country-code": "584",
		"iso_3166-2": "ISO 3166-2:MH",
		region: "Oceania",
		"sub-region": "Micronesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "057",
		"intermediate-region-code": "",
		latitude: 7.131474,
		longitude: 171.184478,
	},
	{
		name: "Martinique",
		"alpha-2": "MQ",
		"alpha-3": "MTQ",
		"country-code": "474",
		"iso_3166-2": "ISO 3166-2:MQ",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 14.641528,
		longitude: -61.024174,
	},
	{
		name: "Mauritania",
		"alpha-2": "MR",
		"alpha-3": "MRT",
		"country-code": "478",
		"iso_3166-2": "ISO 3166-2:MR",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: 21.00789,
		longitude: -10.940835,
	},
	{
		name: "Mauritius",
		"alpha-2": "MU",
		"alpha-3": "MUS",
		"country-code": "480",
		"iso_3166-2": "ISO 3166-2:MU",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: -20.348404,
		longitude: 57.552152,
	},
	{
		name: "Mayotte",
		"alpha-2": "YT",
		"alpha-3": "MYT",
		"country-code": "175",
		"iso_3166-2": "ISO 3166-2:YT",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: -12.8275,
		longitude: 45.166244,
	},
	{
		name: "Mexico",
		"alpha-2": "MX",
		"alpha-3": "MEX",
		"country-code": "484",
		"iso_3166-2": "ISO 3166-2:MX",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Central America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "013",
		latitude: 23.634501,
		longitude: -102.552784,
	},
	{
		name: "Micronesia (Federated States of)",
		"alpha-2": "FM",
		"alpha-3": "FSM",
		"country-code": "583",
		"iso_3166-2": "ISO 3166-2:FM",
		region: "Oceania",
		"sub-region": "Micronesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "057",
		"intermediate-region-code": "",
		latitude: 7.425554,
		longitude: 150.550812,
	},
	{
		name: "Moldova, Republic of",
		"alpha-2": "MD",
		"alpha-3": "MDA",
		"country-code": "498",
		"iso_3166-2": "ISO 3166-2:MD",
		region: "Europe",
		"sub-region": "Eastern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "151",
		"intermediate-region-code": "",
		latitude: 47.411631,
		longitude: 28.369885,
	},
	{
		name: "Monaco",
		"alpha-2": "MC",
		"alpha-3": "MCO",
		"country-code": "492",
		"iso_3166-2": "ISO 3166-2:MC",
		region: "Europe",
		"sub-region": "Western Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "155",
		"intermediate-region-code": "",
		latitude: 43.750298,
		longitude: 7.412841,
	},
	{
		name: "Mongolia",
		"alpha-2": "MN",
		"alpha-3": "MNG",
		"country-code": "496",
		"iso_3166-2": "ISO 3166-2:MN",
		region: "Asia",
		"sub-region": "Eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "030",
		"intermediate-region-code": "",
		latitude: 46.862496,
		longitude: 103.846656,
	},
	{
		name: "Montenegro",
		"alpha-2": "ME",
		"alpha-3": "MNE",
		"country-code": "499",
		"iso_3166-2": "ISO 3166-2:ME",
		region: "Europe",
		"sub-region": "Southern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "039",
		"intermediate-region-code": "",
		latitude: 42.708678,
		longitude: 19.37439,
	},
	{
		name: "Montserrat",
		"alpha-2": "MS",
		"alpha-3": "MSR",
		"country-code": "500",
		"iso_3166-2": "ISO 3166-2:MS",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 16.742498,
		longitude: -62.187366,
	},
	{
		name: "Morocco",
		"alpha-2": "MA",
		"alpha-3": "MAR",
		"country-code": "504",
		"iso_3166-2": "ISO 3166-2:MA",
		region: "Africa",
		"sub-region": "Northern Africa",
		"intermediate-region": "",
		"region-code": "002",
		"sub-region-code": "015",
		"intermediate-region-code": "",
		latitude: 31.791702,
		longitude: -7.09262,
	},
	{
		name: "Mozambique",
		"alpha-2": "MZ",
		"alpha-3": "MOZ",
		"country-code": "508",
		"iso_3166-2": "ISO 3166-2:MZ",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: -18.665695,
		longitude: 35.529562,
	},
	{
		name: "Myanmar",
		"alpha-2": "MM",
		"alpha-3": "MMR",
		"country-code": "104",
		"iso_3166-2": "ISO 3166-2:MM",
		region: "Asia",
		"sub-region": "South-eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "035",
		"intermediate-region-code": "",
		latitude: 21.913965,
		longitude: 95.956223,
	},
	{
		name: "Namibia",
		"alpha-2": "NA",
		"alpha-3": "NAM",
		"country-code": "516",
		"iso_3166-2": "ISO 3166-2:NA",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Southern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "018",
		latitude: -22.95764,
		longitude: 18.49041,
	},
	{
		name: "Nauru",
		"alpha-2": "NR",
		"alpha-3": "NRU",
		"country-code": "520",
		"iso_3166-2": "ISO 3166-2:NR",
		region: "Oceania",
		"sub-region": "Micronesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "057",
		"intermediate-region-code": "",
		latitude: -0.522778,
		longitude: 166.931503,
	},
	{
		name: "Nepal",
		"alpha-2": "NP",
		"alpha-3": "NPL",
		"country-code": "524",
		"iso_3166-2": "ISO 3166-2:NP",
		region: "Asia",
		"sub-region": "Southern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "034",
		"intermediate-region-code": "",
		latitude: 28.394857,
		longitude: 84.124008,
	},
	{
		name: "Netherlands",
		"alpha-2": "NL",
		"alpha-3": "NLD",
		"country-code": "528",
		"iso_3166-2": "ISO 3166-2:NL",
		region: "Europe",
		"sub-region": "Western Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "155",
		"intermediate-region-code": "",
		latitude: 52.132633,
		longitude: 5.291266,
	},
	{
		name: "New Caledonia",
		"alpha-2": "NC",
		"alpha-3": "NCL",
		"country-code": "540",
		"iso_3166-2": "ISO 3166-2:NC",
		region: "Oceania",
		"sub-region": "Melanesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "054",
		"intermediate-region-code": "",
		latitude: -20.904305,
		longitude: 165.618042,
	},
	{
		name: "New Zealand",
		"alpha-2": "NZ",
		"alpha-3": "NZL",
		"country-code": "554",
		"iso_3166-2": "ISO 3166-2:NZ",
		region: "Oceania",
		"sub-region": "Australia and New Zealand",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "053",
		"intermediate-region-code": "",
		latitude: -40.900557,
		longitude: 174.885971,
	},
	{
		name: "Nicaragua",
		"alpha-2": "NI",
		"alpha-3": "NIC",
		"country-code": "558",
		"iso_3166-2": "ISO 3166-2:NI",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Central America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "013",
		latitude: 12.865416,
		longitude: -85.207229,
	},
	{
		name: "Niger",
		"alpha-2": "NE",
		"alpha-3": "NER",
		"country-code": "562",
		"iso_3166-2": "ISO 3166-2:NE",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: 17.607789,
		longitude: 8.081666,
	},
	{
		name: "Nigeria",
		"alpha-2": "NG",
		"alpha-3": "NGA",
		"country-code": "566",
		"iso_3166-2": "ISO 3166-2:NG",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: 9.081999,
		longitude: 8.675277,
	},
	{
		name: "Niue",
		"alpha-2": "NU",
		"alpha-3": "NIU",
		"country-code": "570",
		"iso_3166-2": "ISO 3166-2:NU",
		region: "Oceania",
		"sub-region": "Polynesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "061",
		"intermediate-region-code": "",
		latitude: -19.054445,
		longitude: -169.867233,
	},
	{
		name: "Norfolk Island",
		"alpha-2": "NF",
		"alpha-3": "NFK",
		"country-code": "574",
		"iso_3166-2": "ISO 3166-2:NF",
		region: "Oceania",
		"sub-region": "Australia and New Zealand",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "053",
		"intermediate-region-code": "",
		latitude: -29.040835,
		longitude: 167.954712,
	},
	{
		name: "North Macedonia",
		"alpha-2": "MK",
		"alpha-3": "MKD",
		"country-code": "807",
		"iso_3166-2": "ISO 3166-2:MK",
		region: "Europe",
		"sub-region": "Southern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "039",
		"intermediate-region-code": "",
		latitude: 41.608635,
		longitude: 21.745275,
	},
	{
		name: "Northern Mariana Islands",
		"alpha-2": "MP",
		"alpha-3": "MNP",
		"country-code": "580",
		"iso_3166-2": "ISO 3166-2:MP",
		region: "Oceania",
		"sub-region": "Micronesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "057",
		"intermediate-region-code": "",
		latitude: 17.33083,
		longitude: 145.38469,
	},
	{
		name: "Norway",
		"alpha-2": "NO",
		"alpha-3": "NOR",
		"country-code": "578",
		"iso_3166-2": "ISO 3166-2:NO",
		region: "Europe",
		"sub-region": "Northern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "154",
		"intermediate-region-code": "",
		latitude: 60.472024,
		longitude: 8.468946,
	},
	{
		name: "Oman",
		"alpha-2": "OM",
		"alpha-3": "OMN",
		"country-code": "512",
		"iso_3166-2": "ISO 3166-2:OM",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 21.512583,
		longitude: 55.923255,
	},
	{
		name: "Pakistan",
		"alpha-2": "PK",
		"alpha-3": "PAK",
		"country-code": "586",
		"iso_3166-2": "ISO 3166-2:PK",
		region: "Asia",
		"sub-region": "Southern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "034",
		"intermediate-region-code": "",
		latitude: 30.375321,
		longitude: 69.345116,
	},
	{
		name: "Palau",
		"alpha-2": "PW",
		"alpha-3": "PLW",
		"country-code": "585",
		"iso_3166-2": "ISO 3166-2:PW",
		region: "Oceania",
		"sub-region": "Micronesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "057",
		"intermediate-region-code": "",
		latitude: 7.51498,
		longitude: 134.58252,
	},
	{
		name: "Palestine, State of",
		"alpha-2": "PS",
		"alpha-3": "PSE",
		"country-code": "275",
		"iso_3166-2": "ISO 3166-2:PS",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 31.952162,
		longitude: 35.233154,
	},
	{
		name: "Panama",
		"alpha-2": "PA",
		"alpha-3": "PAN",
		"country-code": "591",
		"iso_3166-2": "ISO 3166-2:PA",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Central America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "013",
		latitude: 8.537981,
		longitude: -80.782127,
	},
	{
		name: "Papua New Guinea",
		"alpha-2": "PG",
		"alpha-3": "PNG",
		"country-code": "598",
		"iso_3166-2": "ISO 3166-2:PG",
		region: "Oceania",
		"sub-region": "Melanesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "054",
		"intermediate-region-code": "",
		latitude: -6.314993,
		longitude: 143.95555,
	},
	{
		name: "Paraguay",
		"alpha-2": "PY",
		"alpha-3": "PRY",
		"country-code": "600",
		"iso_3166-2": "ISO 3166-2:PY",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "South America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "005",
		latitude: -23.442503,
		longitude: -58.443832,
	},
	{
		name: "Peru",
		"alpha-2": "PE",
		"alpha-3": "PER",
		"country-code": "604",
		"iso_3166-2": "ISO 3166-2:PE",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "South America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "005",
		latitude: -9.189967,
		longitude: -75.015152,
	},
	{
		name: "Philippines",
		"alpha-2": "PH",
		"alpha-3": "PHL",
		"country-code": "608",
		"iso_3166-2": "ISO 3166-2:PH",
		region: "Asia",
		"sub-region": "South-eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "035",
		"intermediate-region-code": "",
		latitude: 12.879721,
		longitude: 121.774017,
	},
	{
		name: "Pitcairn",
		"alpha-2": "PN",
		"alpha-3": "PCN",
		"country-code": "612",
		"iso_3166-2": "ISO 3166-2:PN",
		region: "Oceania",
		"sub-region": "Polynesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "061",
		"intermediate-region-code": "",
		latitude: -24.703615,
		longitude: -127.439308,
	},
	{
		name: "Poland",
		"alpha-2": "PL",
		"alpha-3": "POL",
		"country-code": "616",
		"iso_3166-2": "ISO 3166-2:PL",
		region: "Europe",
		"sub-region": "Eastern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "151",
		"intermediate-region-code": "",
		latitude: 51.919438,
		longitude: 19.145136,
	},
	{
		name: "Portugal",
		"alpha-2": "PT",
		"alpha-3": "PRT",
		"country-code": "620",
		"iso_3166-2": "ISO 3166-2:PT",
		region: "Europe",
		"sub-region": "Southern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "039",
		"intermediate-region-code": "",
		latitude: 39.399872,
		longitude: -8.224454,
	},
	{
		name: "Puerto Rico",
		"alpha-2": "PR",
		"alpha-3": "PRI",
		"country-code": "630",
		"iso_3166-2": "ISO 3166-2:PR",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 18.220833,
		longitude: -66.590149,
	},
	{
		name: "Qatar",
		"alpha-2": "QA",
		"alpha-3": "QAT",
		"country-code": "634",
		"iso_3166-2": "ISO 3166-2:QA",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 25.354826,
		longitude: 51.183884,
	},
	{
		name: "Réunion",
		"alpha-2": "RE",
		"alpha-3": "REU",
		"country-code": "638",
		"iso_3166-2": "ISO 3166-2:RE",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: -21.115141,
		longitude: 55.536384,
	},
	{
		name: "Romania",
		"alpha-2": "RO",
		"alpha-3": "ROU",
		"country-code": "642",
		"iso_3166-2": "ISO 3166-2:RO",
		region: "Europe",
		"sub-region": "Eastern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "151",
		"intermediate-region-code": "",
		latitude: 45.943161,
		longitude: 24.96676,
	},
	{
		name: "Russian Federation",
		"alpha-2": "RU",
		"alpha-3": "RUS",
		"country-code": "643",
		"iso_3166-2": "ISO 3166-2:RU",
		region: "Europe",
		"sub-region": "Eastern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "151",
		"intermediate-region-code": "",
		latitude: 61.52401,
		longitude: 105.318756,
	},
	{
		name: "Rwanda",
		"alpha-2": "RW",
		"alpha-3": "RWA",
		"country-code": "646",
		"iso_3166-2": "ISO 3166-2:RW",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: -1.940278,
		longitude: 29.873888,
	},
	{
		name: "Saint Barthélemy",
		"alpha-2": "BL",
		"alpha-3": "BLM",
		"country-code": "652",
		"iso_3166-2": "ISO 3166-2:BL",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 17.9,
		longitude: -62.833333,
	},
	{
		name: "Saint Helena, Ascension and Tristan da Cunha",
		"alpha-2": "SH",
		"alpha-3": "SHN",
		"country-code": "654",
		"iso_3166-2": "ISO 3166-2:SH",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: -24.143474,
		longitude: -10.030696,
	},
	{
		name: "Saint Kitts and Nevis",
		"alpha-2": "KN",
		"alpha-3": "KNA",
		"country-code": "659",
		"iso_3166-2": "ISO 3166-2:KN",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 17.357822,
		longitude: -62.782998,
	},
	{
		name: "Saint Lucia",
		"alpha-2": "LC",
		"alpha-3": "LCA",
		"country-code": "662",
		"iso_3166-2": "ISO 3166-2:LC",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 13.909444,
		longitude: -60.978893,
	},
	{
		name: "Saint Martin (French part)",
		"alpha-2": "MF",
		"alpha-3": "MAF",
		"country-code": "663",
		"iso_3166-2": "ISO 3166-2:MF",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 18.08255,
		longitude: -63.052251,
	},
	{
		name: "Saint Pierre and Miquelon",
		"alpha-2": "PM",
		"alpha-3": "SPM",
		"country-code": "666",
		"iso_3166-2": "ISO 3166-2:PM",
		region: "Americas",
		"sub-region": "Northern America",
		"intermediate-region": "",
		"region-code": "019",
		"sub-region-code": "021",
		"intermediate-region-code": "",
		latitude: 46.941936,
		longitude: -56.27111,
	},
	{
		name: "Saint Vincent and the Grenadines",
		"alpha-2": "VC",
		"alpha-3": "VCT",
		"country-code": "670",
		"iso_3166-2": "ISO 3166-2:VC",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 12.984305,
		longitude: -61.287228,
	},
	{
		name: "Samoa",
		"alpha-2": "WS",
		"alpha-3": "WSM",
		"country-code": "882",
		"iso_3166-2": "ISO 3166-2:WS",
		region: "Oceania",
		"sub-region": "Polynesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "061",
		"intermediate-region-code": "",
		latitude: -13.759029,
		longitude: -172.104629,
	},
	{
		name: "San Marino",
		"alpha-2": "SM",
		"alpha-3": "SMR",
		"country-code": "674",
		"iso_3166-2": "ISO 3166-2:SM",
		region: "Europe",
		"sub-region": "Southern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "039",
		"intermediate-region-code": "",
		latitude: 43.94236,
		longitude: 12.457777,
	},
	{
		name: "Sao Tome and Principe",
		"alpha-2": "ST",
		"alpha-3": "STP",
		"country-code": "678",
		"iso_3166-2": "ISO 3166-2:ST",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Middle Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "017",
		latitude: 0.18636,
		longitude: 6.613081,
	},
	{
		name: "Saudi Arabia",
		"alpha-2": "SA",
		"alpha-3": "SAU",
		"country-code": "682",
		"iso_3166-2": "ISO 3166-2:SA",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 23.885942,
		longitude: 45.079162,
	},
	{
		name: "Senegal",
		"alpha-2": "SN",
		"alpha-3": "SEN",
		"country-code": "686",
		"iso_3166-2": "ISO 3166-2:SN",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: 14.497401,
		longitude: -14.452362,
	},
	{
		name: "Serbia",
		"alpha-2": "RS",
		"alpha-3": "SRB",
		"country-code": "688",
		"iso_3166-2": "ISO 3166-2:RS",
		region: "Europe",
		"sub-region": "Southern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "039",
		"intermediate-region-code": "",
		latitude: 44.016521,
		longitude: 21.005859,
	},
	{
		name: "Seychelles",
		"alpha-2": "SC",
		"alpha-3": "SYC",
		"country-code": "690",
		"iso_3166-2": "ISO 3166-2:SC",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: -4.679574,
		longitude: 55.491977,
	},
	{
		name: "Sierra Leone",
		"alpha-2": "SL",
		"alpha-3": "SLE",
		"country-code": "694",
		"iso_3166-2": "ISO 3166-2:SL",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: 8.460555,
		longitude: -11.779889,
	},
	{
		name: "Singapore",
		"alpha-2": "SG",
		"alpha-3": "SGP",
		"country-code": "702",
		"iso_3166-2": "ISO 3166-2:SG",
		region: "Asia",
		"sub-region": "South-eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "035",
		"intermediate-region-code": "",
		latitude: 1.352083,
		longitude: 103.819836,
	},
	{
		name: "Sint Maarten (Dutch part)",
		"alpha-2": "SX",
		"alpha-3": "SXM",
		"country-code": "534",
		"iso_3166-2": "ISO 3166-2:SX",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 18.030827,
		longitude: -63.073633,
	},
	{
		name: "Slovakia",
		"alpha-2": "SK",
		"alpha-3": "SVK",
		"country-code": "703",
		"iso_3166-2": "ISO 3166-2:SK",
		region: "Europe",
		"sub-region": "Eastern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "151",
		"intermediate-region-code": "",
		latitude: 48.669026,
		longitude: 19.699024,
	},
	{
		name: "Slovenia",
		"alpha-2": "SI",
		"alpha-3": "SVN",
		"country-code": "705",
		"iso_3166-2": "ISO 3166-2:SI",
		region: "Europe",
		"sub-region": "Southern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "039",
		"intermediate-region-code": "",
		latitude: 46.151241,
		longitude: 14.995463,
	},
	{
		name: "Solomon Islands",
		"alpha-2": "SB",
		"alpha-3": "SLB",
		"country-code": "090",
		"iso_3166-2": "ISO 3166-2:SB",
		region: "Oceania",
		"sub-region": "Melanesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "054",
		"intermediate-region-code": "",
		latitude: -9.64571,
		longitude: 160.156194,
	},
	{
		name: "Somalia",
		"alpha-2": "SO",
		"alpha-3": "SOM",
		"country-code": "706",
		"iso_3166-2": "ISO 3166-2:SO",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: 5.152149,
		longitude: 46.199616,
	},
	{
		name: "South Africa",
		"alpha-2": "ZA",
		"alpha-3": "ZAF",
		"country-code": "710",
		"iso_3166-2": "ISO 3166-2:ZA",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Southern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "018",
		latitude: -30.559482,
		longitude: 22.937506,
	},
	{
		name: "South Georgia and the South Sandwich Islands",
		"alpha-2": "GS",
		"alpha-3": "SGS",
		"country-code": "239",
		"iso_3166-2": "ISO 3166-2:GS",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "South America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "005",
		latitude: -54.429579,
		longitude: -36.587909,
	},
	{
		name: "Spain",
		"alpha-2": "ES",
		"alpha-3": "ESP",
		"country-code": "724",
		"iso_3166-2": "ISO 3166-2:ES",
		region: "Europe",
		"sub-region": "Southern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "039",
		"intermediate-region-code": "",
		latitude: 40.463667,
		longitude: -3.74922,
	},
	{
		name: "Sri Lanka",
		"alpha-2": "LK",
		"alpha-3": "LKA",
		"country-code": "144",
		"iso_3166-2": "ISO 3166-2:LK",
		region: "Asia",
		"sub-region": "Southern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "034",
		"intermediate-region-code": "",
		latitude: 7.873054,
		longitude: 80.771797,
	},
	{
		name: "Sudan",
		"alpha-2": "SD",
		"alpha-3": "SDN",
		"country-code": "729",
		"iso_3166-2": "ISO 3166-2:SD",
		region: "Africa",
		"sub-region": "Northern Africa",
		"intermediate-region": "",
		"region-code": "002",
		"sub-region-code": "015",
		"intermediate-region-code": "",
		latitude: 12.862807,
		longitude: 30.217636,
	},
	{
		name: "Suriname",
		"alpha-2": "SR",
		"alpha-3": "SUR",
		"country-code": "740",
		"iso_3166-2": "ISO 3166-2:SR",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "South America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "005",
		latitude: 3.919305,
		longitude: -56.027783,
	},
	{
		name: "Svalbard and Jan Mayen",
		"alpha-2": "SJ",
		"alpha-3": "SJM",
		"country-code": "744",
		"iso_3166-2": "ISO 3166-2:SJ",
		region: "Europe",
		"sub-region": "Northern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "154",
		"intermediate-region-code": "",
		latitude: 77.553604,
		longitude: 23.670272,
	},
	{
		name: "Sweden",
		"alpha-2": "SE",
		"alpha-3": "SWE",
		"country-code": "752",
		"iso_3166-2": "ISO 3166-2:SE",
		region: "Europe",
		"sub-region": "Northern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "154",
		"intermediate-region-code": "",
		latitude: 60.128161,
		longitude: 18.643501,
	},
	{
		name: "Switzerland",
		"alpha-2": "CH",
		"alpha-3": "CHE",
		"country-code": "756",
		"iso_3166-2": "ISO 3166-2:CH",
		region: "Europe",
		"sub-region": "Western Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "155",
		"intermediate-region-code": "",
		latitude: 46.818188,
		longitude: 8.227512,
	},
	{
		name: "Syrian Arab Republic",
		"alpha-2": "SY",
		"alpha-3": "SYR",
		"country-code": "760",
		"iso_3166-2": "ISO 3166-2:SY",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 34.802075,
		longitude: 38.996815,
	},
	{
		name: "Taiwan, Province of China",
		"alpha-2": "TW",
		"alpha-3": "TWN",
		"country-code": "158",
		"iso_3166-2": "ISO 3166-2:TW",
		region: "Asia",
		"sub-region": "Eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "030",
		"intermediate-region-code": "",
		latitude: 23.69781,
		longitude: 120.960515,
	},
	{
		name: "Tajikistan",
		"alpha-2": "TJ",
		"alpha-3": "TJK",
		"country-code": "762",
		"iso_3166-2": "ISO 3166-2:TJ",
		region: "Asia",
		"sub-region": "Central Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "143",
		"intermediate-region-code": "",
		latitude: 38.861034,
		longitude: 71.276093,
	},
	{
		name: "Tanzania, United Republic of",
		"alpha-2": "TZ",
		"alpha-3": "TZA",
		"country-code": "834",
		"iso_3166-2": "ISO 3166-2:TZ",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: -6.369028,
		longitude: 34.888822,
	},
	{
		name: "Thailand",
		"alpha-2": "TH",
		"alpha-3": "THA",
		"country-code": "764",
		"iso_3166-2": "ISO 3166-2:TH",
		region: "Asia",
		"sub-region": "South-eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "035",
		"intermediate-region-code": "",
		latitude: 15.870032,
		longitude: 100.992541,
	},
	{
		name: "Timor-Leste",
		"alpha-2": "TL",
		"alpha-3": "TLS",
		"country-code": "626",
		"iso_3166-2": "ISO 3166-2:TL",
		region: "Asia",
		"sub-region": "South-eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "035",
		"intermediate-region-code": "",
		latitude: -8.874217,
		longitude: 125.727539,
	},
	{
		name: "Togo",
		"alpha-2": "TG",
		"alpha-3": "TGO",
		"country-code": "768",
		"iso_3166-2": "ISO 3166-2:TG",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Western Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "011",
		latitude: 8.619543,
		longitude: 0.824782,
	},
	{
		name: "Tokelau",
		"alpha-2": "TK",
		"alpha-3": "TKL",
		"country-code": "772",
		"iso_3166-2": "ISO 3166-2:TK",
		region: "Oceania",
		"sub-region": "Polynesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "061",
		"intermediate-region-code": "",
		latitude: -8.967363,
		longitude: -171.855881,
	},
	{
		name: "Tonga",
		"alpha-2": "TO",
		"alpha-3": "TON",
		"country-code": "776",
		"iso_3166-2": "ISO 3166-2:TO",
		region: "Oceania",
		"sub-region": "Polynesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "061",
		"intermediate-region-code": "",
		latitude: -21.178986,
		longitude: -175.198242,
	},
	{
		name: "Trinidad and Tobago",
		"alpha-2": "TT",
		"alpha-3": "TTO",
		"country-code": "780",
		"iso_3166-2": "ISO 3166-2:TT",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 10.691803,
		longitude: -61.222503,
	},
	{
		name: "Tunisia",
		"alpha-2": "TN",
		"alpha-3": "TUN",
		"country-code": "788",
		"iso_3166-2": "ISO 3166-2:TN",
		region: "Africa",
		"sub-region": "Northern Africa",
		"intermediate-region": "",
		"region-code": "002",
		"sub-region-code": "015",
		"intermediate-region-code": "",
		latitude: 33.886917,
		longitude: 9.537499,
	},
	{
		name: "Turkey",
		"alpha-2": "TR",
		"alpha-3": "TUR",
		"country-code": "792",
		"iso_3166-2": "ISO 3166-2:TR",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 38.963745,
		longitude: 35.243322,
	},
	{
		name: "Turkmenistan",
		"alpha-2": "TM",
		"alpha-3": "TKM",
		"country-code": "795",
		"iso_3166-2": "ISO 3166-2:TM",
		region: "Asia",
		"sub-region": "Central Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "143",
		"intermediate-region-code": "",
		latitude: 38.969719,
		longitude: 59.556278,
	},
	{
		name: "Turks and Caicos Islands",
		"alpha-2": "TC",
		"alpha-3": "TCA",
		"country-code": "796",
		"iso_3166-2": "ISO 3166-2:TC",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 21.694025,
		longitude: -71.797928,
	},
	{
		name: "Tuvalu",
		"alpha-2": "TV",
		"alpha-3": "TUV",
		"country-code": "798",
		"iso_3166-2": "ISO 3166-2:TV",
		region: "Oceania",
		"sub-region": "Polynesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "061",
		"intermediate-region-code": "",
		latitude: -7.109535,
		longitude: 177.64933,
	},
	{
		name: "Uganda",
		"alpha-2": "UG",
		"alpha-3": "UGA",
		"country-code": "800",
		"iso_3166-2": "ISO 3166-2:UG",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: 1.373333,
		longitude: 32.290275,
	},
	{
		name: "Ukraine",
		"alpha-2": "UA",
		"alpha-3": "UKR",
		"country-code": "804",
		"iso_3166-2": "ISO 3166-2:UA",
		region: "Europe",
		"sub-region": "Eastern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "151",
		"intermediate-region-code": "",
		latitude: 48.379433,
		longitude: 31.16558,
	},
	{
		name: "United Arab Emirates",
		"alpha-2": "AE",
		"alpha-3": "ARE",
		"country-code": "784",
		"iso_3166-2": "ISO 3166-2:AE",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 23.424076,
		longitude: 53.847818,
	},
	{
		name: "United Kingdom of Great Britain and Northern Ireland",
		"alpha-2": "GB",
		"alpha-3": "GBR",
		"country-code": "826",
		"iso_3166-2": "ISO 3166-2:GB",
		region: "Europe",
		"sub-region": "Northern Europe",
		"intermediate-region": "",
		"region-code": "150",
		"sub-region-code": "154",
		"intermediate-region-code": "",
		latitude: 55.378051,
		longitude: -3.435973,
	},
	{
		name: "United States of America",
		"alpha-2": "US",
		"alpha-3": "USA",
		"country-code": "840",
		"iso_3166-2": "ISO 3166-2:US",
		region: "Americas",
		"sub-region": "Northern America",
		"intermediate-region": "",
		"region-code": "019",
		"sub-region-code": "021",
		"intermediate-region-code": "",
		latitude: 37.09024,
		longitude: -95.712891,
	},
	{
		name: "United States Minor Outlying Islands",
		"alpha-2": "UM",
		"alpha-3": "UMI",
		"country-code": "581",
		"iso_3166-2": "ISO 3166-2:UM",
		region: "Oceania",
		"sub-region": "Micronesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "057",
		"intermediate-region-code": "",
		latitude: 0,
		longitude: 0,
	},
	{
		name: "Uruguay",
		"alpha-2": "UY",
		"alpha-3": "URY",
		"country-code": "858",
		"iso_3166-2": "ISO 3166-2:UY",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "South America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "005",
		latitude: -32.522779,
		longitude: -55.765835,
	},
	{
		name: "Uzbekistan",
		"alpha-2": "UZ",
		"alpha-3": "UZB",
		"country-code": "860",
		"iso_3166-2": "ISO 3166-2:UZ",
		region: "Asia",
		"sub-region": "Central Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "143",
		"intermediate-region-code": "",
		latitude: 41.377491,
		longitude: 64.585262,
	},
	{
		name: "Vanuatu",
		"alpha-2": "VU",
		"alpha-3": "VUT",
		"country-code": "548",
		"iso_3166-2": "ISO 3166-2:VU",
		region: "Oceania",
		"sub-region": "Melanesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "054",
		"intermediate-region-code": "",
		latitude: -15.376706,
		longitude: 166.959158,
	},
	{
		name: "Venezuela (Bolivarian Republic of)",
		"alpha-2": "VE",
		"alpha-3": "VEN",
		"country-code": "862",
		"iso_3166-2": "ISO 3166-2:VE",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "South America",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "005",
		latitude: 6.42375,
		longitude: -66.58973,
	},
	{
		name: "Viet Nam",
		"alpha-2": "VN",
		"alpha-3": "VNM",
		"country-code": "704",
		"iso_3166-2": "ISO 3166-2:VN",
		region: "Asia",
		"sub-region": "South-eastern Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "035",
		"intermediate-region-code": "",
		latitude: 14.058324,
		longitude: 108.277199,
	},
	{
		name: "Virgin Islands (British)",
		"alpha-2": "VG",
		"alpha-3": "VGB",
		"country-code": "092",
		"iso_3166-2": "ISO 3166-2:VG",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 18.420695,
		longitude: -64.639968,
	},
	{
		name: "Virgin Islands (U.S.)",
		"alpha-2": "VI",
		"alpha-3": "VIR",
		"country-code": "850",
		"iso_3166-2": "ISO 3166-2:VI",
		region: "Americas",
		"sub-region": "Latin America and the Caribbean",
		"intermediate-region": "Caribbean",
		"region-code": "019",
		"sub-region-code": "419",
		"intermediate-region-code": "029",
		latitude: 18.335765,
		longitude: -64.896335,
	},
	{
		name: "Wallis and Futuna",
		"alpha-2": "WF",
		"alpha-3": "WLF",
		"country-code": "876",
		"iso_3166-2": "ISO 3166-2:WF",
		region: "Oceania",
		"sub-region": "Polynesia",
		"intermediate-region": "",
		"region-code": "009",
		"sub-region-code": "061",
		"intermediate-region-code": "",
		latitude: -13.768752,
		longitude: -177.156097,
	},
	{
		name: "Western Sahara",
		"alpha-2": "EH",
		"alpha-3": "ESH",
		"country-code": "732",
		"iso_3166-2": "ISO 3166-2:EH",
		region: "Africa",
		"sub-region": "Northern Africa",
		"intermediate-region": "",
		"region-code": "002",
		"sub-region-code": "015",
		"intermediate-region-code": "",
		latitude: 24.215527,
		longitude: -12.885834,
	},
	{
		name: "Yemen",
		"alpha-2": "YE",
		"alpha-3": "YEM",
		"country-code": "887",
		"iso_3166-2": "ISO 3166-2:YE",
		region: "Asia",
		"sub-region": "Western Asia",
		"intermediate-region": "",
		"region-code": "142",
		"sub-region-code": "145",
		"intermediate-region-code": "",
		latitude: 15.552727,
		longitude: 48.516388,
	},
	{
		name: "Zambia",
		"alpha-2": "ZM",
		"alpha-3": "ZMB",
		"country-code": "894",
		"iso_3166-2": "ISO 3166-2:ZM",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: -13.133897,
		longitude: 27.849332,
	},
	{
		name: "Zimbabwe",
		"alpha-2": "ZW",
		"alpha-3": "ZWE",
		"country-code": "716",
		"iso_3166-2": "ISO 3166-2:ZW",
		region: "Africa",
		"sub-region": "Sub-Saharan Africa",
		"intermediate-region": "Eastern Africa",
		"region-code": "002",
		"sub-region-code": "202",
		"intermediate-region-code": "014",
		latitude: -19.015438,
		longitude: 29.154857,
	},
];

export const uniqueCountryCodes: any = [
	"+1",
	// "+1 242",
	// "+1 246",
	// "+1 264",
	// "+1 268",
	// "+1 284",
	// "+1 340",
	// "+1 441",
	// "+1 473",
	// "+1 649",
	// "+1 670",
	// "+1 671",
	// "+1 684",
	// "+1 767",
	// "+1 787",
	// "+1 808",
	// "+1 809",
	// "+1 868",
	// "+1 869",
	// "+1 876",
	// "+1664",
	"+20",
	"+212",
	"+213",
	"+216",
	"+218",
	"+220",
	"+221",
	"+222",
	"+223",
	"+224",
	"+225",
	"+226",
	"+227",
	"+228",
	"+229",
	"+230",
	"+231",
	"+232",
	"+233",
	"+234",
	"+235",
	"+236",
	"+237",
	"+238",
	"+240",
	"+241",
	"+242",
	"+243",
	"+244",
	"+245",
	"+246",
	"+247",
	"+248",
	"+249",
	"+250",
	"+251",
	"+253",
	"+254",
	"+255",
	"+256",
	"+257",
	"+260",
	"+261",
	"+262",
	"+263",
	"+264",
	"+265",
	"+266",
	"+267",
	"+268",
	"+269",
	"+27",
	"+291",
	"+297",
	"+298",
	"+299",
	"+30",
	"+31",
	"+32",
	"+33",
	"+34",
	"+345",
	"+350",
	"+351",
	"+352",
	"+353",
	"+354",
	"+355",
	"+356",
	"+358",
	"+359",
	"+36",
	"+370",
	"+371",
	"+372",
	"+373",
	"+374",
	"+375",
	"+376",
	"+377",
	"+378",
	"+380",
	"+381",
	"+382",
	"+385",
	"+386",
	"+387",
	"+389",
	"+39",
	"+40",
	"+41",
	"+420",
	"+421",
	"+423",
	"+43",
	"+44",
	"+45",
	"+46",
	"+47",
	"+48",
	"+49",
	"+500",
	"+501",
	"+502",
	"+503",
	"+504",
	"+505",
	"+506",
	"+507",
	"+509",
	"+51",
	"+52",
	"+53",
	"+537",
	"+54",
	"+55",
	"+56",
	"+57",
	"+58",
	"+590",
	"+591",
	"+593",
	"+594",
	"+595",
	"+596",
	"+597",
	"+598",
	"+599",
	"+60",
	"+61",
	"+62",
	"+63",
	"+64",
	"+65",
	"+66",
	"+670",
	"+672",
	"+673",
	"+674",
	"+675",
	"+676",
	"+677",
	"+678",
	"+679",
	"+680",
	"+681",
	"+682",
	"+683",
	"+685",
	"+686",
	"+687",
	"+688",
	"+689",
	"+690",
	"+691",
	"+692",
	"+7",
	"+7 7",
	"+7 840",
	"+81",
	"+82",
	"+84",
	"+850",
	"+852",
	"+853",
	"+855",
	"+856",
	"+86",
	"+880",
	"+886",
	"+90",
	"+91",
	"+92",
	"+93",
	"+94",
	"+95",
	"+960",
	"+961",
	"+962",
	"+963",
	"+964",
	"+965",
	"+966",
	"+967",
	"+968",
	"+970",
	"+971",
	"+972",
	"+973",
	"+974",
	"+975",
	"+976",
	"+977",
	"+98",
	"+992",
	"+993",
	"+994",
	"+995",
	"+996",
	"+998",
];
