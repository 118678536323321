import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";

export const sendEmail = (data: any) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${url}/submitEmail`, data, AuthHeader)
            .then((res: any) => {
                resolve(res);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};
