import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "sharepoint";

const getRootFolderId = (tenantKey: any, well_id: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(
				`${url}/${tenantKey}/${apiURL}/root-folder-id?well_id=${well_id}`,
				AuthHeader
			)
			.then((_data: any) => {
				if (_data?.data?.folder_id) {
					resolve(_data.data.folder_id);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

const listFolderItems = (
	tenantKey: any,
	well_id: any,
	folder_id: any,
	container_id: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/list-items`,
				{
					tenant_key: tenantKey,
					well_id,
					folder_id,
					container_id,
				},
				AuthHeader
			)
			.then((_data: any) => {
				if (_data?.data) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

const createNewFolder = (
	foldername: any,
	tenantKey: any,
	well_id: any,
	folder_id: any,
	container_id: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/create-folder`,
				{
					foldername,
					tenant_key: tenantKey,
					well_id,
					folder_id,
					container_id,
				},
				AuthHeader
			)
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

const uploadFiles = (
	files: any,
	tenantKey: any,
	well_id: any,
	folder_id: any,
	container_id: any
) => {
	return new Promise((resolve, reject) => {
		const formData = new FormData();

		// Append each file to the formData object
		files.forEach((file: any, index: any) => {
			formData.append(`files`, file);
		});

		// Append other parameters
		formData.append("tenant_key", tenantKey);
		formData.append("well_id", well_id);
		formData.append("folder_id", folder_id);
		formData.append("container_id", container_id);
		axios
			.post(`${url}/${tenantKey}/${apiURL}/upload-files`, formData, AuthHeader)
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

const getFilePreview = (tenantKey: any, file_id: any, container_id: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/file-preview`,
				{
					file_id,
					container_id,
				},
				AuthHeader
			)
			.then((response: any) => {
				console.log(response.data);
				resolve(response.data);
			});
	});
};

const downloadFile = (fileid: any) => {};

const getFileThumbnail = (fileid: any) => {};

const collabo = (tenantKey: any, file_id: any, container_id: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/file-edit`,
				{
					file_id,
					container_id,
				},
				AuthHeader
			)
			.then((response: any) => {
				console.log(response.data);
				resolve(response.data);
			});
	});
};

const deleteFiles = (tenantKey: any, item_ids: any, container_id: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/remove-file`,
				{
					item_ids,
					container_id,
				},
				AuthHeader
			)
			.then((response: any) => {
				console.log(response.data);
				resolve(response.data);
			});
	});
};

export {
	getRootFolderId,
	listFolderItems,
	createNewFolder,
	uploadFiles,
	deleteFiles,
	getFilePreview,
	downloadFile,
	getFileThumbnail,
	collabo,
};
