import { getDeliverables } from "../services/api-server/deliverables";
import { getTask, updateTaskItem } from "../services/api-server/tasks";

function useUpdateDeliverableStatus(
	tenantKey: any,
	taskId: any,
	projectCode: any,
	wellProjectCode: any,
	userData: any
) {
	const updateTaskStatus = () => {
		getTask(tenantKey, taskId)
			.then((taskInfo: any) => {
				getDeliverables(tenantKey, taskId)
					.then((deliverables: any) => {
						const taskData = { ...taskInfo };

						const allDeliverablesStatus = deliverables.map(
							(item: any) => item?.status
						);
						if (allDeliverablesStatus.length > 0) {
							if (
								allDeliverablesStatus.every(
									(status: any) => status === "Resolved"
								)
							) {
								taskData.status = "Resolved";
							} else if (
								allDeliverablesStatus.every((status: any) => status === "New")
							) {
								taskData.status = "New";
							} else {
								taskData.status = "In progress";
							}

							updateTaskItem(
								tenantKey,
								taskId,
								{
									...taskData,
									project_code: projectCode,
									well_project_code: wellProjectCode,
								},
								null,
								userData
							)
								.then((data: any) => {
									console.log("Task updated successfully", data);
								})
								.catch((err: any) => {
									console.log("Error updating task", err);
								});
						}
					})
					.catch((err: any) => {
						console.log("Error fetching deliverables", err);
					});
			})
			.catch((err: any) => {
				console.log("Error fetching task", err);
			});
	};

	// Update the status of task based on all deliverables status
	return { updateTaskStatus };
}

export default useUpdateDeliverableStatus;
