import { Typography } from "antd";
import React, { useEffect, useRef } from "react";

const ExpandableText = (props: any) => {
	const [expand, setExpand] = React.useState(false);
	const [counter, setCounter] = React.useState(0);

	const paragraphRef = useRef(null);

	const containerId = props.containerId;

	return (
		<Typography.Paragraph
			ellipsis={{
				rows: props.rows,
				expandable: true,
				symbol: "Read more",
				onExpand: () => {
					setExpand(true);
				},
			}}
			key={counter}
			ref={paragraphRef}
			// id={containerId}
		>
			{props.paragraphText}
			{expand && (
				<a
					onClick={() => {
						setExpand(false);
						!expand ? setCounter(counter + 0) : setCounter(counter + 1);
						// go to the ref element
						const element: any = document.getElementById(containerId);
						element.scrollIntoView({ top: 0, behavior: "smooth" });
					}}
				>
					{" "}
					Read less
				</a>
			)}
		</Typography.Paragraph>
	);
};

export default ExpandableText;
