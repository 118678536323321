import axios from "axios";
// import store from "../../state/store";
import { AuthHeader, url } from "../../utils/_exports";

export const getToken = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/data`, {
				withCredentials: true,
			})
			.then((res: any) => {
				if (res.data.idToken === "undefined") {
					window.location.href = "/";
				} else {
					if (
						!localStorage.getItem(`idToken`) ||
						!localStorage.getItem(`accessToken`)
					) {
						localStorage.setItem(`idToken`, res.data.idToken);
						localStorage.setItem(`accessToken`, res.data.accessToken);
						resolve(res);
					} else {
						resolve("There is exisitng Tokens");
					}
				}
			})
			.catch((err: any) => {
				console.log(err);
				reject(err);
			});
	});
};

export const refreshToken = (email: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/refresh?email=${email}`, AuthHeader)
			.then((res: any) => {
				if (res.data) {
					localStorage.setItem(`accessToken`, res.data);
					resolve(res.data);
				} else {
					console.log("Cannot set Access Token of undefined");
					reject(false);
				}
			})
			.catch((error: any) => {
				reject(error);
			});
	});
};

// export const getPbiToken = (token: any = null) => {
// 	return new Promise((resolve, reject) => {
// 		let header: any = null;

// 		if (token) {
// 			header = {
// 				headers: {
// 					Authorization: `${token}`,
// 				},
// 			};
// 		} else {
// 			header = AuthHeader;
// 		}
// 		axios
// 			.get(`${url}/pbitoken`, header)
// 			.then((res: any) => {
// 				if (res.data.accessToken) {
// 					localStorage.setItem(
// 						`${Tenant}:pbiToken`,
// 						"Bearer ".concat(res.data.accessToken)
// 					);
// 					resolve("Bearer ".concat(res.data.accessToken));
// 				} else {
// 					reject("Token returned Undefined");
// 				}
// 			})
// 			.catch((err: any) => {
// 				reject(err);
// 			});
// 	});
// };
