import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { SearchOutlined } from "@ant-design/icons";
import {
	Space,
	Divider,
	Input,
	Switch,
	Button,
	Empty,
	Table,
	Image,
	Modal,
	Form,
	Upload,
	List,
	Popover,
	Dropdown,
} from "antd";
import context from "antd/es/app/context";
import dayjs from "dayjs";
import GanttChart from "../components/Charts/GanttChart";
import CheckboxDropdown from "../components/CheckboxDropdown";
import AuditLogModal from "../components/Modal/AuditLogModal";
import SearchButton from "../components/SearchButton";
import { GetAntIcon } from "../utils/ant_icons";
import { checkPrivilege } from "../utils/utils";
import NoAccess from "./NoAccess";
import { useLocation, useNavigate } from "react-router";
import { HomeContext } from "./Home";
import Emitter from "../utils/emitter";
// import "../assets/css/auditlog.css";
// import "../assets/css/welldocs.css";
import { useForm } from "antd/es/form/Form";
import {
	getFilePreview,
	getFileThumbnail,
	collabo,
	createNewFolder,
	downloadFile,
	listFolderItems,
	uploadFiles,
	getRootFolderId,
	deleteFiles,
} from "../services/api-server/welldocs";
import { formatFileSize, getReadableFileType } from "../utils/fileTools";

const WellDetails = (props: any) => {
	const [formRef] = useForm();
	const context: any = useContext(HomeContext);
	const location = useLocation();
	const navigate = useNavigate();

	const [wellDetailsExpand, setWellDetailsExpand] = useState<boolean>(false);
	const [wellInfo, setWellInfo] = useState<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [activePage, setActivePage] = useState<string>("operations");

	//Filtering states
	const [filteredWellList, setFilteredWellList] = useState<any>(null);
	const [filterMode, setFilterMode] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>("");
	const [descMode, setDescMode] = useState<boolean>(false);
	const [selectedFilters, setSelectedFilters] = useState<any>({
		location: [],
		well_purpose: [],
		well_status: [],
	});

	//document search
	const [searchRecord, setSearchRecord] = useState<any>([]);
	const [filteredItems, setFilteredItems] = useState<any>([]);

	//Folder Modal
	const [folderModalOpen, setFolderModalOpen] = useState<boolean>(false);
	const [folderName, setFolderName] = useState<string>("");
	//File Modal
	const [fileModalOpen, setFileModalOpen] = useState<boolean>(false);
	const [fileList, setFileList] = useState<any>([]);

	const [tenantKey, setCurrentTenantKey] = useState<any>(null);
	const [rootFolderId, setRootFolderId] = useState<any>(null);
	const [currentFolderFiles, setCurrentFolderFiles] = useState<any>([]);
	const [currentFolderId, setCurrentFolderId] = useState<any>(null);
	const [containerId, setCurrentContainerId] = useState<any>(null);
	const [wellId, setCurrentWellId] = useState<any>(null);

	const [folderHierarchy, setFolderHierarchy] = useState<any>([]);

	useEffect(() => {
		Emitter.emit("loading", true);
		if (context.currentTenantKey && context.allData) {
			setIsLoading(false);
			let well_id = location.state.well_project_code;
			let tenantKey = context.currentTenantKey;
			let container_id = context.currentTenantData?.container_id;
			try {
				getRootFolderId(tenantKey, well_id).then((response: any) => {
					console.log(response);
					let folder_id = response;

					listFolderItems(tenantKey, well_id, folder_id, container_id).then(
						(response: any) => {
							setCurrentTenantKey(tenantKey);
							setCurrentFolderFiles(response.items || []);
							setFilteredItems(response.items || []);
							setCurrentWellId(well_id);
							setRootFolderId(folder_id || response.folderid);
							setCurrentFolderId(folder_id || response.folderid);
							setCurrentContainerId(context.currentTenantData?.container_id);
							Emitter.emit("loading", false);
						}
					);
				});
			} catch (error: any) {
				console.log(error);
				Emitter.emit("loading", false);
			}
		}
	}, [context.currentTenantKey, context.allData]);

	const handleFolderChange = (
		folderID: any,
		folderName: string,
		index: any = null
	) => {
		Emitter.emit("loading", true);
		try {
			listFolderItems(tenantKey, wellId, folderID, containerId).then(
				(response: any) => {
					if (index == null) {
						setFolderHierarchy([
							...folderHierarchy,
							{ id: folderID, name: folderName },
						]);
					} else if (index == -1) {
						setFolderHierarchy([]);
					} else {
						console.log(index);
						let array = [...folderHierarchy].slice(0, index + 1);
						console.log(array);
						setFolderHierarchy(array);
					}

					setFilteredItems(response);
					setSearchRecord([]);
					setCurrentFolderFiles(response);
					setCurrentFolderId(folderID);
					Emitter.emit("loading", false);
				}
			);
		} catch {
			Emitter.emit("loading", false);
		}
	};

	const handleBeforeUpload = (file: any) => {
		// Prevent the file from being uploaded immediately
		setFileList((prevList: any) => [...prevList, file]);
		return false; // This stops the upload process
	};
	const handleRemove = (file: any) => {
		setFileList((prevList: any) =>
			prevList.filter((item: any) => item.uid !== file.uid)
		);
	};

	//Setting search record
	const handleSearch = (value: any) => {
		console.log(value);

		let record: any = [...searchRecord];
		let recordIndex = record.findIndex((item: any) => {
			return item.columnKey === value.columnKey;
		});
		if (recordIndex !== -1) {
			if (value.value != "") {
				record[recordIndex] = value;
				setSearchRecord(record);
			} else {
				record.splice(recordIndex, 1);
				setSearchRecord(record);
			}
		} else {
			if (value.value != "") {
				record.push(value);
				setSearchRecord(record);
			}
		}

		//Filter data and table columns based on search record
		let data: any = [...currentFolderFiles];
		let filteredData = data
			?.map((element: any) => {
				let bool = record.every((item: any) => {
					if (Array.isArray(element[item.columnKey])) {
						return element[item.columnKey].some((arrayItem: any) =>
							arrayItem.toLowerCase().includes(item.value.toLowerCase())
						);
					} else {
						return element[item.columnKey]
							?.toLowerCase()
							.includes(item.value.toLowerCase());
					}
				});
				if (bool) return element;
			})
			.filter((element: any) => element !== null && element !== undefined);
		setFilteredItems(filteredData);
		data = filteredData;
	};

	const handleFolderCreation = () => {
		Emitter.emit("loading", true);
		try {
			createNewFolder(
				folderName,
				context.currentTenantKey,
				wellId,
				currentFolderId,
				containerId
			).then((response: any) => {
				listFolderItems(
					context.currentTenantKey,
					wellId,
					currentFolderId,
					containerId
				).then((response: any) => {
					setCurrentFolderFiles(response.items || []);
					setFilteredItems(response.items || []);
					setFolderModalOpen(false);
					Emitter.emit("loading", false);
				});
			});
		} catch {
			setFolderModalOpen(false);
			Emitter.emit("loading", false);
		}
		setFolderName("");
	};

	const handleFileUpload = () => {
		Emitter.emit("loading", true);
		try {
			uploadFiles(
				fileList,
				context.currentTenantKey,
				wellId,
				currentFolderId,
				containerId
			).then((response: any) => {
				listFolderItems(
					context.currentTenantKey,
					wellId,
					currentFolderId,
					containerId
				).then((response: any) => {
					setFileModalOpen(false);
					setCurrentFolderFiles(response.items || []);
					setFilteredItems(response.items || []);
					Emitter.emit("loading", false);
				});
			});
		} catch (error: any) {
			setFileModalOpen(false);
			Emitter.emit("loading", false);
		}

		setFileList([]);
	};

	const hasSelectedFilters = () => {
		// Check if any of the filter categories have selected values
		return Object.values(selectedFilters).some(
			(values: any) => values.length > 0
		);
	};

	const filterRender = useMemo(() => {
		const handleMenuClick = (field: any, selectedValues: any[]) => {
			// console.log(`Selected: ${field} - ${selectedValues}`);
			// Update the selected filters state
			setSelectedFilters({ ...selectedFilters, [field]: selectedValues });

			localStorage.setItem(
				"selectedFilters",
				JSON.stringify({ ...selectedFilters, [field]: selectedValues })
			);
		};

		const handleClearAllFilters = () => {
			// Reset all filters to empty arrays
			setSelectedFilters({
				location: [],
				well_purpose: [],
				well_status: [],
			});
			setFilterMode(false);

			// Clear session storage for filter values and checkboxes
			localStorage.removeItem("selectedFilters");

			// Object.keys(selectedFilters).forEach((field) => {
			// 	const storageKey = generateStorageKey(field);
			// 	localStorage.removeItem(storageKey);
			// });

			setTimeout(() => {
				setFilterMode(true);
			}, 5);
		};

		return (
			<>
				{" "}
				<CheckboxDropdown
					options={[
						{
							label: "onshore",
							value: "onshore",
						},
						{ label: "offshore", value: "offshore" },
					]}
					onChange={(selectedValues) =>
						handleMenuClick("location", selectedValues)
					}
					onClear={""}
					defaultValue={selectedFilters.location}
					Key="location"
					label="Location"
				/>
				<CheckboxDropdown
					options={context?.allData?.well_purpose?.map((wp: any) => ({
						label: wp.name,
						value: wp.uuid,
					}))}
					onChange={(selectedValues) =>
						handleMenuClick("well_purpose", selectedValues)
					}
					onClear={""}
					defaultValue={selectedFilters["well_purpose"]}
					Key="well_purpose"
					label="Well purpose"
				/>
				<CheckboxDropdown
					options={context?.allData?.well_status?.map((ws: any) => ({
						label: ws.name,
						value: ws.uuid,
					}))}
					onChange={(selectedValues) =>
						handleMenuClick("well_status", selectedValues)
					}
					onClear={""}
					defaultValue={selectedFilters["well_status"]}
					Key="well_status"
					label="Well status"
				/>
				<div className="test">
					<Button
						type="link"
						style={{ color: "rgba(255, 255, 255, 0.5)" }}
						onClick={handleClearAllFilters}
					>
						Reset All
					</Button>
				</div>
			</>
		);
	}, [filterMode, selectedFilters]);

	const folderMemo = useMemo(() => {
		const breadcrumbRenderer = () => {
			console.log(folderHierarchy);
			if (folderHierarchy.length > 0) {
				return (
					<>
						<a
							className="document-breadcrumb-clickable-text"
							onClick={() => {
								handleFolderChange(rootFolderId, "", -1);
							}}
						>
							Contents
						</a>
						{folderHierarchy.map((folder: any, index: number) => {
							if (index === folderHierarchy.length - 1) {
								return (
									<>
										<span className="document-breadcrumb-seperator">/</span>
										<span className="document-breadcrumb-text">
											{folder.name || "undefined"}
										</span>
									</>
								);
							} else {
								return (
									<>
										<span className="document-breadcrumb-seperator">/</span>
										<a
											className="document-breadcrumb-clickable-text"
											onClick={() => {
												handleFolderChange(folder.id, folder.name, index);
											}}
										>
											{folder.name || "undefined"}
										</a>
									</>
								);
							}
						})}
					</>
				);
			} else {
				return <span>Contents</span>;
			}
		};

		return (
			<>
				{" "}
				<div className="document-container-header">
					<div id={"documentBreadcrumb"} className="document-breadcrumb">
						{breadcrumbRenderer()}
					</div>
					<Button
						style={{ marginLeft: "auto" }}
						icon={GetAntIcon("folder-outline")}
						onClick={() => {
							setFolderModalOpen(true);
						}}
					>
						Create folder
					</Button>
					<Button
						icon={GetAntIcon("upload")}
						onClick={() => {
							setFileModalOpen(true);
						}}
					>
						Upload file
					</Button>
				</div>
				<Table
					rowHoverable={false}
					footer={undefined}
					pagination={false}
					style={{ width: "100%" }}
					rootClassName="audit-log-table" //styling only
					className="documents-table"
					bordered
					dataSource={filteredItems}
					// scroll={tableHeight && { y: tableHeight, x: 0 }}
					tableLayout="auto"
					columns={[
						{
							title: (
								<SearchButton
									handleSearch={handleSearch}
									headerName="Name"
									columnKey="name"
									key={"phase"}
								/>
							),
							dataIndex: "name",
							key: "name",
							render: (value: any, record: any, index: any) => {
								console.log(record);
								return (
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "center",
										}}
									>
										<a
											className="table-clickable-text"
											onClick={() => {
												if (record.folder) {
													handleFolderChange(record.id, record.name);
												} else if (record.file) {
													getFilePreview(
														context.currentTenantKey,
														record.id,
														containerId
													).then((response: any) => {
														window.open(response?.getUrl, "__blank");
													});
												}
											}}
										>
											{value}
										</a>
										{record.file && (
											<Dropdown
												menu={{
													items: [
														{
															label: "Preview",
															key: "0",
															onClick: () => {
																getFilePreview(
																	context.currentTenantKey,
																	record.id,
																	containerId
																).then((response: any) => {
																	window.open(response?.getUrl, "__blank");
																});
															},
														},
														{
															label: "Edit",
															key: "1",
															onClick: () => {
																collabo(
																	context.currentTenantKey,
																	record.id,
																	containerId
																).then((response: any) => {
																	console.log(response);
																	window.open(response?.link.webUrl, "__blank");
																});
															},
														},
														{
															label: "Download",
															key: "2",
															onClick: () => {
																if (record[`@microsoft.graph.downloadUrl`]) {
																	window.open(
																		record[`@microsoft.graph.downloadUrl`],
																		"__blank"
																	);
																}
															},
														},
														{
															type: "divider",
														},
														{
															label: "Delete",
															key: "4",
															onClick: () => {
																deleteFiles(
																	tenantKey,
																	[record.id],
																	containerId
																).then((response: any) => {
																	let tempFolderFiles = [...filteredItems];
																	tempFolderFiles.splice(index, 1);

																	setFilteredItems(tempFolderFiles);

																	Emitter.emit("alert", {
																		type: "success",
																		message: "Item has been deleted",
																		description: "",
																		top: true,
																		closeable: false,
																		timeout: 3000,
																	});
																});
															},
														},
													],
												}}
												trigger={["click"]}
											>
												<a
													style={{ color: "white" }}
													onClick={(e) => e.preventDefault()}
												>
													{GetAntIcon("ellipsis")}
												</a>
											</Dropdown>
										)}
									</div>
								);
							},
						},
						{
							title: (
								<SearchButton
									handleSearch={handleSearch}
									headerName="Owner"
									columnKey="owner"
									key={"owner"}
								/>
							),
							dataIndex: "owner",
							key: "owner",
							render: () => {
								return "-";
							},
							// width: 200,
						},
						{
							title: "Date added",
							dataIndex: "createdDateTime",
							key: "creation_date",
							render: (value: any) => (
								<span>{value && dayjs(value)?.format("D MMM YYYY")}</span>
							),
							sorter: (a: any, b: any) => {
								const stringA = a.createdDateTime || "";
								const stringB = b.createdDateTime || "";
								return stringA.localeCompare(stringB);
							},
						},
						{
							title: "Type",
							dataIndex: "type",
							key: "type",
							render: (value: any, record: any) => {
								if (record.folder) {
									return <span>File folder</span>;
								} else if (record.file) {
									return (
										<span>{getReadableFileType(record.file.mimeType)}</span>
									);
								}
							},
						},
						{
							title: "Size",
							dataIndex: "size",
							key: "size",
							sorter: (a: any, b: any) => a.size - b.size,
							render: (value: any) => {
								// let renderedValue = (value / 1000000).toFixed(4);
								// return <span>{value && renderedValue}</span>;

								return <span>{formatFileSize(value)}</span>;
							},
						},
					]}
				></Table>
			</>
		);
	}, [currentFolderId, filteredItems, currentFolderFiles, folderHierarchy]);

	return (
		<>
			{!isLoading && (
				<div
					className="generic-container"
					style={{ gap: "20px", overflow: "auto" }}
				>
					{/* Top Container */}
					<div
						className="well-details-top-content main-container"
						id="main-container"
					>
						<div className="container-header">
							<div
								className="panel-info"
								onClick={() => {
									setWellDetailsExpand(!wellDetailsExpand);
								}}
							>
								<span>Well details</span>
								{wellDetailsExpand ? GetAntIcon("up") : GetAntIcon("downarrow")}
							</div>{" "}
						</div>
						{wellDetailsExpand ? (
							<div className="details-content project-details-container">
								{/* <div
									style={{
										display: "flex",
										flexDirection: "column",
										maxWidth: "30%",
										height: "100%",
										position: "relative",
										gap: "20px",
									}}
								>
									{renderMetaData()}
									<div></div>
									<div
										style={{
											marginTop: "auto",
											display: "flex",
											flexDirection: "row",
											gap: "10px",
										}}
									>
										{checkPrivilege(props.params.userRole, [
											"project-manager",
										]) && (
											<a
												style={{ marginTop: "auto" }}
												onClick={() => {
													navigate("well-settings", {
														replace: false,
														state: { ...projectInfo, ...wellInfo },
													});
												}}
												onContextMenu={(e: any) => {
													const location = `${window.location.href}/well-settings`;
													handleContextMenu(e, { link: location });
												}}
											>
												{GetAntIcon("setting")} Well settings
											</a>
										)}
										<a
											onClick={() => {
												setAuditOpen(true);
											}}
										>
											{GetAntIcon("history")} Audit log
										</a>
									</div>
								</div>

								<Divider
									type="vertical"
									style={{ height: "100%", margin: "0px 20px" }}
								></Divider>
								<div
									style={{
										flex: "1",
										// background: "rgba(31,31,31,0.2)",
										// padding: "0px 20px",
									}}
								>
									<Divider
										orientation="left"
										style={{
											fontSize: "14px",
											color: "rgba(255, 255, 255, 0.45)",
											marginTop: "0px",
										}}
									>
										Well details
									</Divider>
									<div>{renderDetailData()}</div>
								</div> */}
							</div>
						) : (
							<></>
						)}
					</div>
					{/* Bottom Container */}
					<div
						className="well-details-bottom-content main-container"
						style={{
							minHeight: "85%",
							flex: 1,
							width: "100%",
							// maxHeight: "100%",
							overflow: "hidden",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<div className="project-header">
							<div
								style={{ display: "flex", flexDirection: "row", gap: "10px" }}
							>
								<div
									className={
										activePage === "operations" ? "project-active-button" : ""
									}
									style={{
										display: "flex",
										padding: "4px 15px",
										gap: "8px",
										cursor: "pointer",
									}}
									onClick={() => {
										setActivePage("operations");
									}}
								>
									{GetAntIcon("product")}
									<span>Operations</span>
								</div>{" "}
								<div
									className={
										activePage === "documents" ? "project-active-button" : ""
									}
									style={{
										display: "flex",
										padding: "4px 15px",
										gap: "8px",
										cursor: "pointer",
									}}
									onClick={() => {
										setActivePage("documents");
									}}
								>
									{GetAntIcon("file2")}
									<span>Documents</span>
								</div>
							</div>
							{/* <div
								style={{
									display: "flex",
									flex: "1",
									justifyContent: "flex-end",
									alignItems: "center",
									gap: "15px",
								}}
							>
								<Input
									placeholder="Search by task name"
									className="input-clearable"
									style={{
										maxWidth: "265px",
										display: activePage === "documents" ? "none" : "",
									}}
									onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
										setSearchValue(e.currentTarget.value);
										e.currentTarget.blur();
									}}
									onChange={(e) => {
										if (e.target.value === "") {
											setSearchValue("");
										}
									}}
									allowClear
									prefix={<SearchOutlined />}
								/>
								<div style={{ display: "flex", flexDirection: "row" }}>
									{" "}
									<div
										className={
											filterMode || hasSelectedFilters()
												? "project-active-button"
												: ""
										}
										style={{ padding: "4px 10px", cursor: "pointer" }}
										onClick={() => {
											setFilterMode(!filterMode);
										}}
									>
										{GetAntIcon("filter")}
									</div>
								</div>
							</div> */}
						</div>
						<div
							className="generic-content project-overview-container"
							style={{
								flex: 1,
								display: "flex",
								flexWrap: "inherit",
								flexDirection: "column",
								alignItems: "flex-start",
								padding: 0,
								gap: 0,
								overflowX: "auto",
								maxHeight: "100%",
							}}
						>
							{/* <div
								style={{
									display: "flex",
									padding: filterMode ? "12px 30px" : "0 30px",
									justifyContent: "flex-end",
									alignItems: "center",
									gap: "5px",
									transform: filterMode ? "translateY(0)" : "translateY(-150%)",
									alignSelf: "stretch",
									background: "rgba(6,45,48)",
									transition: "0.3s ease-in-out",
								}}
							>
								{filterMode && (
									<>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												gap: "10px",
												padding: "8px",
												marginRight: "20px",
												alignItems: "center",
												height: "40px",
											}}
										>
											Hide excluded items
											<Switch
												// checkedChildren="Hide excluded items"
												// unCheckedChildren="Show excluded items"
												defaultValue={hideExcluded}
												// style={{ padding: "8px" }}
												onChange={(checked: any) => {
													setHideExcluded(checked);
												}}
											/>
										</div>
										{activePage === "kanban" && (
											<>
												<CheckboxDropdown
													options={projectRoleAssignmentList}
													onChange={(selectedValues) =>
														handleMenuClick("assigned_to", selectedValues)
													}
													onClear={""}
													defaultValue={selectedFilters.assigned_to}
													Key="assigned_to"
													label="Assigned to"
												/>
												<CheckboxDropdown
													options={[
														{ label: "New", value: "New" },
														{
															label: "To do",
															value: "To do",
														},
														{ label: "In Progress", value: "In progress" },
														{ label: "Late", value: "Late" },
														{
															label: "Resolved",
															value: "Resolved",
														},
													]}
													onChange={(selectedValues) =>
														handleMenuClick("status", selectedValues)
													}
													onClear={""}
													defaultValue={selectedFilters.status}
													Key="status"
													label="Status"
												/>
												<div className="test">
													<Button
														type="link"
														style={{ color: "rgba(255, 255, 255, 0.5)" }}
														onClick={handleClearAllFilters}
													>
														Reset All
													</Button>
												</div>
											</>
										)}
									</>
								)}
							</div> */}
							{activePage === "operations" && (
								<div
									style={{
										display: "flex",
										flex: 1,
										alignItems: "flex-start",
										padding: "40px 30px",
										gap: "25px",
										alignSelf: "stretch",
										overflow: "auto",
									}}
								>
									<Empty
										className="no-data-empty"
										style={{ margin: "auto", fontSize: "20px" }}
										// image={elementalSvg}
										description="There are no operations in this well"
									/>
									{/* {wellOperationList?.length > 0 ? (
										renderWellOperationList()
									) : (
										<Empty
											className="no-data-empty"
											style={{ margin: "auto", fontSize: "20px" }}
											image={elementalSvg}
											description="There are no operations in this well"
										/>
									)} */}
								</div>
							)}
							{activePage === "documents" && (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										flex: 1,
										width: "100%",
										padding: "40px 30px",
										gap: "40px",
									}}
								>
									<div id="quickAccess">
										<span style={{ fontSize: "16px" }}>Quick Access</span>
									</div>
									<div
										id="documentContainer"
										style={{
											gap: "20px",
											display: "flex",
											flexDirection: "column",
										}}
									>
										{folderMemo}
									</div>
								</div>
							)}
						</div>
					</div>
					{/* <AuditLogModal
						tenantKey={context?.currentTenantKey}
						identifierKeys={{
							project_code: projectInfo?.project_code,
							well_project_code: wellInfo?.well_project_code,
						}}
						type={"well_project"}
						label={"Well project"}
						open={auditOpen}
						handleClose={() => {
							setAuditOpen(false);
						}}
					/> */}

					<Modal
						closable={false}
						width={"40%"}
						centered
						styles={{ header: { padding: "12px 16px" } }}
						open={folderModalOpen}
						title="Create new folder"
						footer={() => (
							<div
								style={{ display: "flex", flexDirection: "row", gap: "10px" }}
							>
								<Button
									onClick={() => {
										setFolderName("");
										setFolderModalOpen(false);
									}}
								>
									Cancel
								</Button>
								<Button
									type="primary"
									disabled={folderName == ""}
									onClick={handleFolderCreation}
								>
									Save
								</Button>
							</div>
						)}
					>
						<div
							style={{ display: "flex", flexDirection: "column", gap: "10px" }}
						>
							<span>Folder name</span>
							<Input
								value={folderName}
								onChange={(event: any) => {
									console.log(event.target.value);
									setFolderName(event.target.value);
								}}
								allowClear
							></Input>
						</div>
					</Modal>
					<Modal
						closable={false}
						width={"40%"}
						centered
						styles={{ header: { padding: "12px 16px" } }}
						open={fileModalOpen}
						title="Upload new file"
						footer={() => (
							<div
								style={{ display: "flex", flexDirection: "row", gap: "10px" }}
							>
								<Button
									onClick={() => {
										setFileList([]);
										setFileModalOpen(false);
									}}
								>
									Cancel
								</Button>
								<Button
									type="primary"
									disabled={fileList.length == 0}
									onClick={handleFileUpload}
								>
									Save
								</Button>
							</div>
						)}
					>
						<Upload
							multiple
							beforeUpload={handleBeforeUpload}
							fileList={fileList}
							onRemove={handleRemove}
						>
							<Button icon={GetAntIcon("upload")}>Select Files</Button>
						</Upload>
					</Modal>
				</div>
			)}
			{/* {!wellInfo && !isLoading && (
				<>
					<NoAccess text={"This well does not exist."} />
				</>
			)} */}
		</>
	);
};

export default WellDetails;
